import { Card, Icon, InlineStack, ResourceItem, ResourceList, Text, Tooltip } from "@shopify/polaris"
import { DeleteIcon, PlusIcon, QuestionCircleIcon } from "@shopify/polaris-icons"
import { Placeholder } from "@src/components/Placeholder"
import { MetaContext } from "@src/context/MetaWrap"
import { EntityNField2 } from "@src/datatypes"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { addChildQl } from "@src/reducers/treeSlice"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
}

const addIcon = <Icon source={PlusIcon} tone="base" />
const deleteIcon = <Icon source={DeleteIcon} tone="base" />

const EntitiesSelectList: React.FC<Props> = ({ }) => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()

  const focusTree = useAppSelector(state => state.tree.focusTree)
  const qlKind = useAppSelector(state => state.tree.qlKind)
  const entities = useAppSelector(state => state.tree.entities)

  const metaCtx = useContext(MetaContext)

  const visibleEntities0 = (focusTree?.children || []).map(c => c.enf)
  const visibleEntities = entities
  /*?.filter(e => {
    return _.intersection(e.entityTypes, entityFilters).length > 0
  })*/

  const addHandler = (item: EntityNField2) => {
    console.log("add", item)
    //console.log("userEntity.path", fieldsPath)
    dispatch(addChildQl({ item }))
  }

  const deleteHandler = (item: EntityNField2) => {
    //dispatch(deleteChildQl({ path: userEntity?.path, item }))
  }


  const childEntitiesVisible = visibleEntities && visibleEntities.length > 0

  const selectedItems = (focusTree?.children || []).map((e: any) => e.field)



  return (
    <div className="scrollable" style={{ padding: "0 10px 0 0" }}>

      {childEntitiesVisible ? (
        <Card>
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={visibleEntities}
            showHeader={false}
            renderItem={item => {
              const { entity, field, desc, entityTypes } = item


              const addAct = {
                content: addIcon,
                onAction: () => addHandler(item),
              }

              const deleteAct = {
                content: deleteIcon,
                onAction: () => deleteHandler(item),
              }

              const qlAct = selectedItems.includes(field) ? deleteAct : addAct

              const regularShortcutActions: any = [qlAct]


              const media = (
                <Tooltip content={desc}>
                  <Icon source={QuestionCircleIcon} tone="base" />
                </Tooltip>
              )

              return (
                <ResourceItem
                  id={field}
                  url={""}
                  media={media}
                  shortcutActions={regularShortcutActions}
                  persistActions={true}
                  onClick={() => { }}
                >
                  <InlineStack>
                    <div>
                      <InlineStack>

                        <Text variant="bodyLg" fontWeight="regular" as="h2">
                          {entity}
                        </Text>
                      </InlineStack>

                      <div>{field}</div>
                    </div>
                  </InlineStack>
                </ResourceItem>
              )
            }}
          />
        </Card>
      ) : null}
      <Placeholder height="40px" />
    </div>
  )
}

export default EntitiesSelectList
