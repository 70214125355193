import { PreviewData, UserEntity } from "@src/datatypes"
import useUserEntityQuery from "@src/hooks/useUserEntityQuery"
import devSettings from "@src/logic/devSettings"
import { serverClient } from "@src/services/ServerClient"
import React, { Dispatch, createContext, useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { MetaContext, WrapProps } from "./MetaWrap"
import { ResolvedUrlContext } from "./ResolveUrlWrap"
import { useAppSelector } from "@src/reducers/hooks"

type LoadPreview = (store: string, force: boolean) => void
type StarMut = (star: boolean) => void

type UserEntityMut = {
  userEntity: UserEntity
  loadPreview: LoadPreview
  loadingPreview: boolean
  setStar: StarMut
  preview: PreviewData[]
  scopes: string[]
  setUserEntity: Dispatch<React.SetStateAction<UserEntity>>
}

const EmptyState: UserEntityMut = {
  userEntity: null,
  loadPreview: undefined,
  loadingPreview: false,
  setStar: undefined,
  preview: [],
  scopes: [],
  setUserEntity: undefined
}
export const UserEntityContext = createContext<UserEntityMut>(EmptyState)

const UserEntityWrap: React.FC<WrapProps> = ({ children }) => {
  const nav = useNavigate()
  const metaCtx = useContext(MetaContext)
  const { fieldsPath } = useContext(ResolvedUrlContext)

  const [userEntity, setUserEntity] = useState<UserEntity>(null)
  const [preview, setPreview] = useState<PreviewData[]>([])
  const [scopes, setScopes] = useState<string[]>([])
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)

  const isQlAdd = useAppSelector(state => state.basic.isQlAdd)

  const setStar = (star: boolean) => setUserEntity({ ...userEntity, star })

  const loadPreview = (store: string, force: boolean) => {
    setLoadingPreview(true)
    serverClient
      .runPreviewQuery("", store, metaCtx.apiVersion, fieldsPath, force)
      .then(preview => {
        setPreview(preview?.data || [])
        setScopes(preview?.scopes?.split(","))
      })
      .finally(() => setLoadingPreview(false))
  }

  const { isLoading } = useUserEntityQuery(true, fieldsPath, isQlAdd,  newUserEntity => {
    setUserEntity(newUserEntity)

    if (devSettings.previewOn) {
      loadPreview(newUserEntity.store, false)
    }

    if (!newUserEntity.hasSubscription) {
      nav("/subscribe")
      return
    }
  })

  shopify.loading(isLoading || loadingPreview)

  return (
    <UserEntityContext.Provider value={{ userEntity, loadPreview, loadingPreview, setStar, preview, scopes, setUserEntity }}>
      {" "}
      {userEntity && children}
    </UserEntityContext.Provider>
  )
}

export default UserEntityWrap
