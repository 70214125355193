import { PayloadAction, createSlice, current } from "@reduxjs/toolkit"
import { EntityNField2 } from "@src/datatypes"
import { createTreeData, extendTree, findNodeByPath } from "@src/logic/helpers"
import _ from "lodash"
import { TreeData } from "./basicSlice"
import { MetaContext } from "@src/context/MetaWrap"

export interface BasicState {
  //
  fieldsPath: string[]
  entities: EntityNField2[]
  //
  isQlAdd: boolean // create new ql
  treeData: TreeData // whole created tree
  focusTree: TreeData // currentry visible tree node
  qlKind?: string // what kind ql query is opened
}

const initialState: BasicState = {
  //
  fieldsPath: [],
  entities: [],
  //
  isQlAdd: false,
  treeData: undefined,
  focusTree: undefined,
  qlKind: undefined,
}

export const treeSlice = createSlice({
  name: "tree",
  initialState,
  reducers: {
    // initial step
    startNewQl: (state, action: PayloadAction<{ root: TreeData; entities: EntityNField2[] }>) => {
      state.isQlAdd = true

      state.entities = [...action.payload.entities]
      state.fieldsPath = [action.payload.root.field]
      state.treeData = action.payload.root
      state.focusTree = { ...action.payload.root }

      state.qlKind = undefined
    },

    setTreeFieldsPath: (state, action: PayloadAction<{ path: string[]; enfs: EntityNField2[] }>) => {
      state.fieldsPath = action.payload.path

      const st = current(state)
      const clon = _.cloneDeep(st.treeData) // clone current tree
      const tmpTree = extendTree(clon, action.payload.enfs) // extend it with new node
      state.treeData = tmpTree
      const targetNode = findNodeByPath(clon, action.payload.path)
      state.focusTree = { ...targetNode }
    },

    selectTreeNode: (state, action: PayloadAction<{ path: string[]; entities: EntityNField2[] }>) => {
      state.fieldsPath = action.payload.path
      state.entities = [...action.payload.entities]
    },

    stopNewQl: (state, action: PayloadAction<any>) => {
      state.isQlAdd = false
      state.treeData = undefined
      state.focusTree = undefined

      state.qlKind = undefined
    },

    addChildQl: (state, action: PayloadAction<{ item: EntityNField2 }>) => {
      const st = current(state)
      const child = createTreeData(action.payload.item, false)

      const clon = _.cloneDeep(st.treeData)
      const targetNode = findNodeByPath(clon, st.fieldsPath)

      const safeChildren = targetNode.children || []
      const presentFields = safeChildren.map(e => e.field)
      if (!presentFields.includes(child.field)) {
        targetNode.children = [...safeChildren, child]

        state.treeData = { ...clon } as TreeData
        //state.focusTree = { ...targetNode }
      }
    },

    deleteChildQl: (state, action: PayloadAction<{ path: string[]; item: EntityNField2 }>) => {
      const st = current(state)

      const clon = _.cloneDeep(st.treeData)
      const targetNode = findNodeByPath(clon, action.payload.path)

      const safeChildren = targetNode.children || []
      const presentFields = safeChildren.map(e => e.field)

      const field = action.payload.item.field
      if (presentFields.includes(field)) {
        targetNode.children = safeChildren.filter(e => e.field != field)

        state.treeData = { ...clon } as TreeData
        //state.focusTree = { ...targetNode }
      }
    },
  },
})

export const { setTreeFieldsPath, startNewQl, stopNewQl, addChildQl, deleteChildQl, selectTreeNode } = treeSlice.actions

export default treeSlice.reducer
