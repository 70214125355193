import {
  BlockStack,
  Box,
  Button,
  Card,
  FormLayout,
  Icon,
  IconSource,
  InlineGrid,
  InlineStack,
  Listbox,
  MenuGroupDescriptor,
  Page,
  Text,
  TextField,
} from "@shopify/polaris"
import { ChevronRightIcon, PlusIcon, SettingsIcon } from "@shopify/polaris-icons"
import { Placeholder } from "@src/components/Placeholder"
import { KindStars } from "@src/datatypes"
import Const from "@src/logic/const"
import { openCustomQl, setTreeQls, startNewQl, stopNewQl, TreeData } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { setStarState } from "@src/reducers/starSlice"
import Meta from "@src/services/Meta"
import { serverClient } from "@src/services/ServerClient"
import _ from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  title: string
  text?: string
  icon: IconSource
  stars: KindStars
  kind: string
  qls: TreeData[]
  handler: () => unknown
  selectHandler: (v: any) => unknown
  addHandler: (v: any) => unknown
  openQlHandler: (label: string, kind: string) => unknown
}

const MenuCell: React.FC<Props> = ({ title, text, kind, icon, handler, stars, selectHandler, addHandler, openQlHandler, qls }) => {
  //if (qls?.length > 0) {console.log(qls)}
  const hasQls = qls?.length > 0

  return (
    <Card>
      <InlineStack align="space-between">
        <InlineStack blockAlign="center">
          <Box padding="100">
            <Icon source={icon} />
          </Box>
          <Text as="h3" variant="headingMd">
            {" "}
            {title}
          </Text>
        </InlineStack>
        <InlineStack gap="100">
          <Button onClick={() => addHandler(kind)} icon={PlusIcon} />
          <Button onClick={handler} icon={ChevronRightIcon} />
        </InlineStack>
      </InlineStack>
      <BlockStack gap="050">
        <div style={{ padding: "5px" }}>
          {!!stars ? (
            <Listbox onSelect={selectHandler}>
              {stars.paths.map((starPath, idx) => {
                const key = `${title}-${idx}`
                const fieldsPath = starPath.path.split(",")
                const visFieldsPath = "/" + fieldsPath.slice(1).join("/")
                if (visFieldsPath.length == 0) return null
                return (
                  <Listbox.Option key={key} value={"" + fieldsPath.join("/")}>
                    {visFieldsPath}
                  </Listbox.Option>
                )
              })}
            </Listbox>
          ) : null}
          <Placeholder height="10px" />
          {hasQls ? (
            <>
              {/* <Text as="p"><Icon source={DatabaseIcon} /> custom queries</Text> */}
              <Listbox onSelect={v => openQlHandler(v, kind)}>
                {qls?.map(ql => {
                  const key = ql.label
                  return (
                    <Listbox.Option key={key} value={ql.label}>
                      {ql.label}
                    </Listbox.Option>
                  )
                })}
              </Listbox>
            </>
          ) : null}
        </div>
      </BlockStack>
    </Card>
  )
}

const MenuPage: React.FC<{}> = ({}) => {
  const [text, setText] = useState("")
  const [sent, setSent] = useState(false)

  const nav = useNavigate()
  const dispatch = useAppDispatch()

  const storeStars = useAppSelector(slice => slice.stars.stars)
  const treeQls = useAppSelector(slice => slice.basic.treeQls)

  const selectHandler = (v: string) => {
    nav(`/${v}`)
  }

  const addHandler = (kind: string) => {
    const meta = Meta[kind]
    const root = { id: meta.field, field: meta.field, entity: meta.entity, temporal: false } as TreeData
    dispatch(startNewQl(root))
    //nav(`/${kind}?add=`)
    nav(`/${kind}`)
  }

  const openQlHandler = useCallback(
    (label: string, kind: string) => {
      const found = treeQls[`QL,${kind}`]?.find(e => e.label == label)
      if (found) {
        //console.log("open ql", found)
        dispatch(openCustomQl({ kind, tree: found }))
        nav(`/${kind}`)
      }
    },
    [treeQls]
  )

  useEffect(() => {
    fetch(`/api/stars?store=${Const.Store}`)
      .then(response => response.json())
      .then(result => {
        //console.log(result)
        dispatch(setStarState(result))

        const qlList = Object.entries(_.groupBy(result.qls, "kind")).map(([k, list]) => [k, list.map((e: any) => JSON.parse(e.path.join("")))])
        const qlObj = Object.fromEntries(qlList)
        dispatch(setTreeQls(qlObj))
        return result
      })
  }, [])

  /*useQuery({
    //enabled: !!store,
    queryKey: ["storeStars"],
    queryFn: () =>
     
    refetchOnWindowFocus: false,
  })*/

  const createCell = (kind: string, stars: KindStars) => {
    return (
      <MenuCell
        {...{ ...Meta[kind], stars, key: kind, kind, selectHandler, addHandler, openQlHandler, qls: treeQls[`QL,${kind}`] }}
        handler={() => {
          dispatch(stopNewQl({}))
          nav(`/${kind}`)
        }}
      />
    )
  }

  const kinds = Object.keys(Meta)

  const actionGroups: MenuGroupDescriptor[] = [
    {
      icon: SettingsIcon,
      title: "",
      actions: [
        { content: "Permissions", onAction: () => nav("/scopes") },
        { content: "Subscriptions", onAction: () => nav("/subscribe") },
        { content: "Support", onAction: () => nav("/support") },
      ],
    },
  ].filter(Boolean)

  const handleTextChange = (newText: string) => setText(newText)

  const sendHandler = () => {
    const data = { store: Const.Store, details: text }

    serverClient.sendFeedback(data).then(r => {
      setSent(true)
      shopify.toast.show("Thank you")
    })
  }

  return (
    <Page fullWidth title="menu" actionGroups={actionGroups}>
      <div className="scrollable" style={{ padding: "5px" }}>
        {sent ? null : (
          <Card padding="300" background="bg-fill-caution">
            <InlineStack gap="100" align="space-between" blockAlign="stretch">
              <TextField
                label="please provide information of what functional are you searching for or what bugs you have found here"
                value={text}
                onChange={handleTextChange}
                multiline={2}
                autoComplete="off"
                disabled={sent}
                minLength={10}       
                         
    
              />
              <Button variant="primary" onClick={sendHandler} accessibilityLabel="send" disabled={text.length < 10} tone="critical">
                Send
              </Button>
            </InlineStack>
          </Card>
        )}
        <Placeholder height="20px" />
        <InlineGrid columns="2" gap="200">
          {kinds.map(
            kind =>
              createCell(
                kind,
                storeStars?.find(el => el.kind === kind)
              ) || []
          )}
        </InlineGrid>
        <Placeholder height="30px" />
      </div>
    </Page>
  )
}

export default MenuPage
