import { extractName } from "./helpers"

const urlParams = new URLSearchParams(window.location.search)
const store = extractName(urlParams.get("shop"))

const Const = {
  ApiVersion: "2024-10",
  Store: store,
  ClickDebounceTime: 300,
  SaveDebounceTime: 1000,

  //
  FieldsNoSelectAllAmount: 2,
  FieldsNoCollapseAmount: 10,
  //
  ApiVersions: [ "2024-10"],
}

export default Const
