import { Banner, Button, Card, Icon, InlineStack, ResourceItem, ResourceList, Text, Tooltip } from "@shopify/polaris"
import { CaretDownIcon, DeleteIcon, KeyIcon, LockFilledIcon, PlayIcon, PlusIcon, QuestionCircleIcon, StarFilledIcon } from "@shopify/polaris-icons"
import { CurrentStateContext } from "@src/context/CurrentStateWrap"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { EntityNField, EntityNField2 } from "@src/datatypes"
import devSettings from "@src/logic/devSettings"
import { proposedScopes, resolveScopes } from "@src/logic/scopesResolve"
import { addChildQl, deleteChildQl } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import _ from "lodash"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import FieldsComponent from "./FieldsComponent"
import { Placeholder } from "./Placeholder"
import ChildFieldsComponent from "./ChildFieldsComponent"
import { MetaContext } from "@src/context/MetaWrap"

interface Props {
  fetchHandler: (selected: string[]) => void
  qlFetchHandler: (kind: string, label?: string) => void
  fetchChildHandler: (field: string, query: string) => void
  loading: boolean
}

const downIcon = <Icon source={CaretDownIcon} tone="base" />
const playIcon = <Icon source={PlayIcon} tone="base" />
const keyIcon = <Icon source={KeyIcon} tone="base" />
const addIcon = <Icon source={PlusIcon} tone="base" />
const deleteIcon = <Icon source={DeleteIcon} tone="base" />

const EntitiesList: React.FC<Props> = ({ fetchHandler, qlFetchHandler, fetchChildHandler, loading }) => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()

  const childField = useAppSelector(state => state.basic.childField)
  const pageInfo = useAppSelector(state => state.basic.pageInfo)
  const isQlAdd = useAppSelector(state => state.basic.isQlAdd)
  const treeData = useAppSelector(state => state.basic.treeData)
  const focusTree = useAppSelector(state => state.basic.focusTree)
  const qlKind = useAppSelector(state => state.basic.qlKind)

  //const treeDataChildren = (treeData?.children || []).map(e => e.field)
  //const [selectedQlNodes, setSelectedQlNodes] = useState([])

  const metaCtx = useContext(MetaContext)
  const { entityFilters, selectedFields, flushState } = useContext(CurrentStateContext)
  const { userEntity, preview, scopes } = useContext(UserEntityContext)
  const { fieldsEnfs, entity, entities, updateResolvedUrlState } = useContext(ResolvedUrlContext)

  const visibleEntities = entities?.filter(e => {
    return _.intersection(e.entityTypes, entityFilters).length > 0
  })

  const diveHandler = (entity: EntityNField) => {
    const newFieldsEnfs = [...fieldsEnfs].concat([entity])
    const result = updateResolvedUrlState(newFieldsEnfs, false)
    if (result.path) {
      flushState()
      nav(`/${result.path}`)
    }
  }

  const askPermissionHandler = (scopes: any[], store: string) => {
    //const scopes = resolveScopes(message)
    if (scopes.length == 0) {
      return
    }
    //console.log("request scopes", scopes)
    serverClient.runAskPermissionQuery(store, scopes, false).then(url => {
      if (!url) return
      //console.log('request url', url)
      open(url, "_top")
    })
  }

  const addHandler = (item: EntityNField2) => {
    dispatch(addChildQl({ path: userEntity?.path, item }))
  }

  const deleteHandler = (item: EntityNField2) => {
    dispatch(deleteChildQl({ path: userEntity?.path, item }))
  }

  const fieldsParams = { isFavorite: false, fetchHandler, fetchChildHandler, loading }

  const hasCustomQl = qlKind != undefined
  const childEntitiesVisible = visibleEntities && visibleEntities.length > 0 && !hasCustomQl

  const selectedItems = (focusTree?.children || []).map(e => e.field)

  //const hasPreviewError = preview && preview.resultType === 'error' && devSettings.previewOn && _.isArray(preview.details)
  //console.log(preview)
  //console.log('path for scopes', )
  const requiredScopes = proposedScopes(userEntity?.path, scopes)
  const banner =
    requiredScopes.length > 0 && scopes?.length > 0 ? (
      <Banner tone="info">
        <p>
          Please, enable following permission scopes:{" "}
          <Text as="span" fontWeight="bold">
            {requiredScopes.join(", ")}
          </Text>{" "}
          otherwise some data may be unavailable. <Button icon={KeyIcon} onClick={() => askPermissionHandler(requiredScopes, userEntity?.store)} />
        </p>
      </Banner>
    ) : null

  return (
    <div className="scrollable" style={{ padding: "0 10px 0 0" }}>
      {banner}
      <Placeholder height="10px" />
      {/* <Text as="p">{focusTree?.entity || "undef"}</Text> */}

      {hasCustomQl ? (
        <>
          <ChildFieldsComponent {...{ tree: treeData, metaCtx, key: treeData.entity, isRoot: true, qlFetchHandler }} />
          {treeData?.children?.map(tree => (
            <ChildFieldsComponent {...{ tree, metaCtx, key: tree.entity, isRoot: false }} />
          ))}
        </>
      ) : (
        <FieldsComponent {...fieldsParams} />
      )}
      <Placeholder height="10px" />
      {childEntitiesVisible ? (
        <Card>
          <ResourceList
            resourceName={{ singular: "customer", plural: "customers" }}
            items={visibleEntities}
            showHeader={false}
            loading={loading}
            renderItem={item => {
              const { entity, field, desc, entityTypes } = item

              const previewItem = preview.find(pe => pe.entityField == field)
              const isPreviewError = previewItem?.resultType === "error" && devSettings.previewOn
              const isScopeLocked = isPreviewError && previewItem.details.length > 0 && ("" + previewItem.details).indexOf("Access denied") != -1
              const askScopes = isScopeLocked && resolveScopes("" + previewItem.details)
              const hasNext = !pageInfo || (pageInfo && field == childField && pageInfo.hasNextPage) || childField != field

              const askPermissionsAct = {
                content: keyIcon,
                onAction: () => askPermissionHandler(askScopes, userEntity?.store),
              }

              const fetchChildAct = {
                content: playIcon,
                onAction: () => fetchChildHandler(field, ""),
              }

              const diveAct = {
                content: downIcon,
                onAction: () => diveHandler(item),
              }

              const addAct = {
                content: addIcon,
                onAction: () => addHandler(item),
              }

              const deleteAct = {
                content: deleteIcon,
                onAction: () => deleteHandler(item),
              }

              const qlAct = selectedItems.includes(field) ? deleteAct : addAct

              const regularShortcutActions: any = isScopeLocked
                ? [askPermissionsAct]
                : [isQlAdd ? qlAct : undefined, hasNext && !isQlAdd ? fetchChildAct : undefined].filter(e => e)

              const previewTone = isPreviewError ? "critical" : "subdued"
              //let entityHelpUrl = documentLink(entity, entityTypes, metaCtx.apiVersion)

              const media = (
                <Tooltip content={desc}>
                  <Icon source={isScopeLocked ? LockFilledIcon : QuestionCircleIcon} tone="base" />
                </Tooltip>
              )

              return (
                <ResourceItem
                  id={field}
                  url={""}
                  media={media}
                  shortcutActions={regularShortcutActions}
                  persistActions={true}
                  onClick={() => diveHandler(item)}
                >
                  <InlineStack>
                    <div>
                      <InlineStack>
                        {item.star ? (
                          <Text as="h3">
                            <Icon source={StarFilledIcon} />
                          </Text>
                        ) : null}

                        <Text variant="bodyLg" fontWeight="regular" as="h2" tone={previewTone}>
                          {entity}
                        </Text>
                      </InlineStack>

                      <div>{field}</div>
                    </div>
                  </InlineStack>
                </ResourceItem>
              )
            }}
          />
        </Card>
      ) : null}
      <Placeholder height="40px" />
    </div>
  )
}

export default EntitiesList
