import { Button, OptionList, Popover } from "@shopify/polaris"
import { FilterIcon } from "@shopify/polaris-icons"
import { CurrentStateContext } from "@src/context/CurrentStateWrap"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { UserEntity } from "@src/datatypes"
import Const from "@src/logic/const"
import { serverClient } from "@src/services/ServerClient"
import _, { debounce } from "lodash"
import React, { useCallback, useContext, useEffect, useState } from "react"

interface Props {}

const options = [
  {
    value: "regular",
    label: "Regular",
  },
  {
    value: "money",
    label: "Money",
  },
  {
    value: "address",
    label: "Address",
  },
  {
    value: "metafield",
    label: "Metafield",
  },
  {
    value: "connection",
    label: "Connection",
  },
  {
    value: "deprecated",
    label: "Deprecated",
  },
]

const EntityFilterPopover: React.FC<Props> = ({}) => {
  const [popoverActive, setPopoverActive] = useState(false)

  const { userEntity } = useContext(UserEntityContext)
  const { entities } = useContext(ResolvedUrlContext)
  const { entityFilters, setEntityFilters } = useContext(CurrentStateContext)

  const togglePopoverActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), [])

  useEffect(() => {
    setPopoverActive(false)
  }, [userEntity])

  const saveUserEntity = (filters: string[], store: string) => {
    const data: UserEntity = {
      store,
      kind: userEntity.path[0],
      path: userEntity.path,
      star: userEntity.star,
      starId: -1,
      filters,
      fields: { ...userEntity.fields },
      exists: false,
      hasSubscription: false,
      apiVersion: "",
      isQlAdd: false,
    }
    serverClient.saveUserEntityQuery(data)
  }

  // save state to server
  const saveSelectedFieldsDebounced = _.debounce(saveUserEntity, Const.SaveDebounceTime)

  const handleEntityFilterChange = (userEntity: UserEntity, value: string[]) => {
    saveSelectedFieldsDebounced(value, userEntity.store)
  }

  const updateSelectionDebounced = debounce(handleEntityFilterChange, Const.ClickDebounceTime)

  const handleSelectionChange = useCallback(
    (value: any) => {
      setEntityFilters(value)
      updateSelectionDebounced(userEntity, value)
    },
    [userEntity]
  )

  const countEntities = (entityType: string) => (entities && _.sum(entities.map(e => (e.entityTypes.includes(entityType) ? 1 : 0)))) || 0

  const enrichedOptions = options
    .map(option => {
      const count = countEntities(option.value)
      return {
        ...option,
        count,
        active: true,
        label: "" + count + " " + option.label,
      }
    })
    .filter(e => e.count)

  const sumCount = enrichedOptions.reduce((acc, el) => el.count + acc, 0)

  const showAll = enrichedOptions.length < 2 || sumCount < 5
  //const forceOptions = showAll ? enrichedOptions.map(o => o.value) : []

  const activator = <Button onClick={togglePopoverActive} disclosure={true} disabled={showAll} icon={FilterIcon} />

  return (
    <Popover fixed active={popoverActive} activator={activator} onClose={togglePopoverActive} fluidContent={true}>
      <Popover.Pane fixed>
        {entityFilters ? (
          <div style={{ whiteSpace: "nowrap" }}>
            <OptionList allowMultiple={true} onChange={handleSelectionChange} options={enrichedOptions} selected={entityFilters} />
          </div>
        ) : null}
      </Popover.Pane>
    </Popover>
  )
}

export default EntityFilterPopover
