import { BlockStack, Box, Button, ButtonGroup, Card, Checkbox, FormLayout, InlineStack, Link, List, Page, Text, TextField } from "@shopify/polaris"
import { Placeholder } from "@src/components/Placeholder"
import Const from "@src/logic/const"
import { goMenu } from "@src/reducers/basicSlice"
import { useAppDispatch } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import React, { useState } from "react"
import { useNavigate } from "react-router-dom"

const SupportPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const nav = useNavigate()

  const store = Const.Store

  const [anonym, setAnonym] = useState(false)
  const [text, setText] = useState("")
  const [sent, setSent] = useState(false)

  const handleAnonymChange = (checked: boolean) => setAnonym(checked)

  const handleTextChange = (newText: string) => setText(newText)

  const backHandler = () => {
    setAnonym(false)
    setText("")
    setSent(false)
    dispatch(goMenu({}))
    nav("/")
  }

  const sendHandler = () => {
    const data = anonym ? { details: text } : { store, details: text }
    //setSent(true)
    serverClient.sendFeedback(data).then(r => {
      setSent(true)
      shopify.toast.show("Thank you")
    })
  }

  return (
    <Page title="Support" backAction={{ content: "menu", onAction: () => backHandler() }}>
      <div className="scrollable" style={{ padding: "0 0px 0 0" }}>
        <Card padding="600">
          <BlockStack gap="300">
            <Text as="h2" variant="headingMd">
              We appreciate your feedback not only because it helps our product to evolve, but mainly to make life better, simpler and happier for
              both parties. Please don't hesitate to contact us for:
            </Text>
            <Box paddingBlockEnd="200">
              <List>
                <List.Item>issues and questions</List.Item>
                <List.Item>feature requests</List.Item>
                <List.Item>ideas and thoughts</List.Item>
                <List.Item>anything not from the list</List.Item>
              </List>
            </Box>
          </BlockStack>
          <FormLayout>
            <Checkbox label="anonymously" checked={anonym} onChange={handleAnonymChange} disabled={sent} />
            <TextField label="details" value={text} onChange={handleTextChange} multiline={10} autoComplete="off" disabled={sent} minLength={10} />
          </FormLayout>
          <Placeholder height="20px" />
          <InlineStack align="end">
            <ButtonGroup>
              <Button variant="secondary" onClick={backHandler} accessibilityLabel="send">
                Close
              </Button>
              {sent ? null : (
                <Button variant="primary" onClick={sendHandler} accessibilityLabel="send" disabled={text.length < 10}>
                  Send
                </Button>
              )}
            </ButtonGroup>
          </InlineStack>
          <Placeholder height="20px" />
          <Text as="h2" variant="headingMd">
            alternatively, write us by email <Link url="mailto:skemify0@gmail.com">skemify0@gmail.com</Link>
          </Text>
        </Card>
      </div>
    </Page>
  )
}

export default SupportPage
