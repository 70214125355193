import { Entity, EntityNField, EntityNField2 } from "@src/datatypes"
import { asEntityFields, resolveChildren } from "@src/logic/treeResolve"
import { resolveEntities } from "@src/reducers/StateResolver"
import { setFieldsPath } from "@src/reducers/basicSlice"
import { useAppDispatch } from "@src/reducers/hooks"
import MetaCtx from "@src/services/MetaCtx"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useLocation, useNavigate, useSearchParams } from "react-router-dom"

export type ResolvedUrlCtx = {
  fieldsPath: string[]
  lastField: string
  fieldsEnfs: EntityNField[]
  resolved: Boolean
  entity: Entity
  entities: EntityNField2[]
  //isAddQl: boolean
}

//type Dispatch = React.Dispatch<React.SetStateAction<ResolvedUrlCtx>>
type StateMutator = (enfs: EntityNField[], up: boolean) => any
export type ResolvedUrlCtxMut = ResolvedUrlCtx & { updateResolvedUrlState: StateMutator }

const emptyState: ResolvedUrlCtx = {
  fieldsPath: undefined,
  lastField: undefined,
  fieldsEnfs: undefined,
  resolved: false,
  entity: undefined,
  entities: undefined,
  //isAddQl: false,
}

const useResolveUrlState = (props: { metaCtx: MetaCtx }): ResolvedUrlCtxMut => {
  const { pathname } = useLocation()
  const [params] = useSearchParams()

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const tmpPath = pathname.split("/").filter(e => e) // from  url
  const withPfx = pathname.startsWith("/fav/")
  //const isAddQl = params.has("add")

  //console.log("isNewQl", isAddQl)

  const fieldsUrl: string[] = withPfx ? tmpPath.slice(1) : tmpPath

  const [thisState, setThisState] = useState<ResolvedUrlCtx>(emptyState)

  const updateResolvedUrlState: StateMutator = (enfs: EntityNField[], up: boolean) => {
    if (enfs.length > 0) {
      const { entity, entities } = resolveEntities(props.metaCtx, enfs)
      const fieldsPath = asEntityFields(enfs)
      const lastField = _.last(fieldsPath)
      setThisState({ fieldsEnfs: enfs, entity, entities, fieldsPath, lastField, resolved: true })
      dispatch(setFieldsPath({ path: fieldsPath, enfs, up }))
      return { path: fieldsPath.join("/") }
    } else {
      setThisState(emptyState)
      return {}
    }
  }

  // we have to update correct state
  useEffect(() => {
    if (fieldsUrl === thisState.fieldsPath || !props.metaCtx) return

    const resolveChildrenReturn = resolveChildren(props.metaCtx, fieldsUrl)
    const tmpPath2 = resolveChildrenReturn.map(e => e.field).join("/")
    const newUrl = withPfx ? "/fav/" + tmpPath2 : "/" + tmpPath2

    updateResolvedUrlState(resolveChildrenReturn, false)

    navigate(newUrl, { replace: true })
  }, [props.metaCtx])

  return { ...thisState, updateResolvedUrlState }
}

export default useResolveUrlState
