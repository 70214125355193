import { UserEntity } from "@src/datatypes"
import axios, { AxiosRequestConfig, AxiosResponse } from "axios"

class ServerClient {
  apiVersion: string

  host: string
  constructor() {
    //console.log("server client created")
    this.host = window.location.hostname
  }

  /*async handleError(op:string, p:Promise<any>) {
    p.catch(error => {

    })
  } */

  async handleError(op: string, logic: () => Promise<any>) {
    try {
      return logic()
    } catch (error) {
      console.error("Request failed: " + op, error.response ? error.response.data : error.message)
      throw error
    }
  }

  async chooseSubscriptionQuery(store: string, plan: string): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/api/subscription?store=${store}&plan=${plan}`,
    }

    return await this.handleError("choose permissions", () => axios(config))
  }

  async flushCacheQuery(store: string, scope: string): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/api/flush?store=${store}&scope=${scope}`,
    }

    return await this.handleError("ask permissions", () => axios(config)).then(r => console.log(r.data))
  }

  async sendFeedback(feedback: any): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/api/feedback`,
      data: feedback,
    }

    return await this.handleError(`feedback`, () => axios(config))
  }

  async exportCsvQuery(store: string, path: string[], fields: string[], format: string, apiVersion: string): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/api/export`,
      data: {
        store,
        path,
        fields,
        format,
        version: apiVersion,
      },
    }

    return await this.handleError(`export ${format}`, () => axios(config)).then(r => r?.data?.data || [])
  }

  async runAskPermissionQuery(store: string, scopes: string[], force: Boolean): Promise<any> {
    const config: AxiosRequestConfig = {
      method: "get",
      url: `/api/install?store=${store}&scopes=${scopes.join(",")}&force=${force}`,
    }

    const response: AxiosResponse = await this.handleError("ask permissions", () => axios(config))
    if (response.data) {
      return response.data
    } else {
      return undefined
    }
  }

  async saveUserEntityQuery(data: UserEntity): Promise<any> {
    //console.log("runUserEntityQuery", path, opened, fields)
    const config: AxiosRequestConfig = {
      method: "post",
      url: `/api/userEntity`,
      data,
    }

    const response: AxiosResponse = await this.handleError("saveUserEntityQuery", () => axios(config))
    //console.log(response.data);
    if (response.data.data) {
      return response.data.data
    } else {
      return []
    }
  }

  async runPreviewQuery(accessToken: string, store: string, version: string, path: string[], force: boolean): Promise<any> {
    const headers = {
      "X-Shopify-Access-Token": accessToken,
    }

    const config: AxiosRequestConfig = {
      method: "post",
      url: `/api/preview`,
      headers,
      data: { store, version, path, force },
    }

    const response: AxiosResponse = await this.handleError("preview", () => axios(config))
    if (response.data) {
      return response.data
    } else {
      return []
    }
  }

  async runProxyQuery(accessToken: string, query: any, path: string): Promise<any> {
    const headers = {
      //'Content-Type': 'application/json',
      "X-Shopify-Access-Token": accessToken,
    }

    const config: AxiosRequestConfig = {
      method: "post",
      url: `/api/${path}`,
      headers,
      data: query,
    }

    const response: AxiosResponse = await this.handleError("proxy", () => axios(config))
    if (response.data) {
      return response.data
    } else {
      //console.log(response.data)
      return {}
    }
  }
}

export const serverClient = new ServerClient()
