import { Grid, Page } from "@shopify/polaris";
import TreeComponent from "@src/components/TreeComponent";
import { MetaContext } from "@src/context/MetaWrap";
import { useAppDispatch } from "@src/reducers/hooks";
import React, { useContext, useEffect } from "react";
import EntitiesSelectList from "./EntitiesSelectList";

const PowerPage: React.FC = () => {

    const metaCtx = useContext(MetaContext)
    const dispatch = useAppDispatch()

    useEffect(() => {
        dispatch(metaCtx.control.startRoot("orders"))
    }, [])

    return <Page
        fullWidth
        title="Power page">
        <Grid gap={{ xs: "6", sm: "6", md: "6", lg: "6", xl: "6" }}>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <TreeComponent />
            </Grid.Cell>
            <Grid.Cell columnSpan={{ xs: 6, sm: 6, md: 6, lg: 6, xl: 6 }}>
                <EntitiesSelectList />
            </Grid.Cell>
        </Grid>


    </Page >
}

export default PowerPage;