import React from "react";


export const Placeholder: React.FC<{ height?: string; width?: string }> = ({ height = "auto", width = "auto" }) => {
  return (
    <div
      style={{
        height: height,
        width: width,
      }}
    />
  )
}
