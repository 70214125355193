const { inflate } = require("pako")

export const fetchZip = async (path: string) => {
  try {
    const response = await fetch(path)
    if (!response.ok) {
      return undefined
    }

    const blob = await response.blob()
    const arrayBuffer = await blob.arrayBuffer()
    const decompressedData = inflate(arrayBuffer, { to: "string" })

    const jsonData = JSON.parse(decompressedData)
    return jsonData
  } catch (error) {
    console.error("unable to load zip resource", error)
    return undefined
  }
}
