import { Entity, EntityNField } from "@src/datatypes"
import MetaCtx from "@src/services/MetaCtx"
import _ from "lodash"

// covered by findEntityByName
const findEntityById = (metaCtx: MetaCtx, roots: Entity[], id: number): Entity | null => {
  const topLevel = roots.find(e => e.id === id)
  if (topLevel) {
    return topLevel
  } else {
    const ef = roots.map(e => findEntityField(e.entities, id)).find(e => e != null)
    return ef ? metaCtx.getEntityByName(ef.entity) : null
  }
}

const findEntityByName = (roots: Entity[], name: string): Entity | null => roots.find(e => e.name === name)

const findEntityField = (roots: EntityNField[], id: number): EntityNField | null => {
  const topLevel = roots.find(e => e.id === id)
  if (topLevel) {
    return topLevel
  } else {
    return null
  }
}

const findFieldsPath = (metaCtx: MetaCtx, roots: Entity[], rootField: string, ids: number[]) => {
  if (ids.length < 1) return null

  const id0 = ids[0]
  const topEntity: Entity = roots.find(e => e.id === id0)

  let path = [rootField]
  let entities: EntityNField[] = topEntity.entities
  for (let i = 1; i < ids.length && entities; i++) {
    const id = ids[i]
    const entity = entities.find(e => e.id === id)
    const nextEntity = findEntityById(metaCtx, roots, id)
    if (entity && nextEntity) {
      entities = nextEntity.entities
      path.push(entity.field)
    } else {
      return null
    }
  }
  return path
}

const collectPathLinks = (root: Entity[], paths: string[]) => {
  return paths.map((e, i) => paths.slice(0, -i)).filter(e => e.length)
}

const collectIdLinks = (ids: any[]) => {
  return ids.map((e, i) => ids.slice(0, -i)).filter(e => e.length)
}

const collectCsvIdLinks = (ids: any[]) => {
  const tail = collectIdLinks(ids.slice(1))
    .reverse()
    .map(e => e.concat("id").join("_"))
  return ["id"].concat(tail)
}

const removeNewlines = (str: string) => (str && str.replace(/\r?\n|\r/g, "")) || ""

const objectToCsv = (headers: string[], obj: any) =>
  headers.map((h: string) => {
    const v: any = obj[h]
    //console.log(h, v)
    if (!_.isEmpty(v)) return _.isString(v) ? '"' + removeNewlines(v) + '"' : '"' + v + '"'
    else return ""
  })

const findParentEntity = (x: any, id: number) => null as any

export { findEntityById, findEntityByName, findParentEntity, findFieldsPath, collectPathLinks, collectIdLinks, collectCsvIdLinks, objectToCsv }
