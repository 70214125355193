import { PolarisProvider, QueryProvider } from "@src/components/providers"
import CurrentStateWrap from "@src/context/CurrentStateWrap"
import MetaWrap from "@src/context/MetaWrap"
import ResolveUrlWrap from "@src/context/ResolveUrlWrap"
import UserEntityWrap from "@src/context/UserEntityWrap"
import EntityPage from "@src/pages/EntityPage"
import MenuPage from "@src/pages/MenuPage"
import ScopesPage from "@src/pages/ScopesPage"
import SubscriptionPage from "@src/pages/SubscriptionPage"
import { store } from "@src/reducers/store"
import React from "react"
import { Provider } from "react-redux"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"
import "./Application.scss"
import SupportPage from "@src/pages/SupportPage"
import devSettings from "@src/logic/devSettings"
import PowerPage from "@src/power/PowerPage"

const Application: React.FC = () => {
  const wrap = (elem: React.ReactNode) => (
    <ResolveUrlWrap>
      <UserEntityWrap>
        <CurrentStateWrap>{elem}</CurrentStateWrap>
      </UserEntityWrap>
    </ResolveUrlWrap>
  )

  const wrap2 = (elem: React.ReactNode) => (
    <ResolveUrlWrap>
      {elem}
    </ResolveUrlWrap>
  )

  return (
    <div id="erwt">
      <PolarisProvider>
        <BrowserRouter>
          <QueryProvider>
            <Provider store={store}>
              <MetaWrap>
                <Routes>
                  <Route path="/" element={<MenuPage />} />
                  <Route path="/subscription-confirm" element={<Navigate replace to="/" />} />
                  <Route path="/subscribe" element={<SubscriptionPage />} />
                  <Route path="/scopes" element={<ScopesPage />} />
                  <Route path="/support" element={<SupportPage />} />

                  <Route path="/*" element={devSettings.powerMode ? wrap2(<PowerPage />) : wrap(<EntityPage />)} />
                </Routes>
              </MetaWrap>
            </Provider>
          </QueryProvider>
        </BrowserRouter>
      </PolarisProvider>
    </div>
  )
}

export default Application
