import { BlockStack, Box, Button, Card, InlineStack, List, Page, Text, TextField } from "@shopify/polaris"
import { Placeholder } from "@src/components/Placeholder"
import Const from "@src/logic/const"
import { goMenu } from "@src/reducers/basicSlice"
import { useAppDispatch } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

interface Props {
  name: string
  value: string
  trial: number
  period: string
  amount: number
  features: string[]
  special?: string
  handler: Function
  planPair: PlanData
}

interface PlanData {
  plan: string
  usedTrialDays: number
}

const GoodbuyPlan: React.FC<{}> = () => {
  const [text, setText] = useState("")
  const [sent, setSent] = useState(false)
  const handleTextChange = (newText: string) => setText(newText)

  const sendHandler = () => {
    const data = { store: Const.Store, details: text }

    serverClient.sendFeedback(data).then(r => {
      setSent(true)
      shopify.toast.show("Thank you")
    })
  }

  const features = [
    "we're gratefull for your attention to our product",
    "please, tell us what functionality you search for",
    "because next time you probably will find it here",
  ]

  return (
    <Card background="bg-fill-info-secondary">
      <Box paddingBlockStart="800" padding="400">
        <BlockStack align="center" inlineAlign="center">
          <Text as="span" variant="headingXl" alignment="center">
            Good Buy
          </Text>
          <Placeholder width="10px" height="20px" />

          <div style={{ minHeight: "90px" }}>
            <List>
              {features.map((feature: string, idx: number) => (
                <List.Item key={`${idx}`}>
                  <Text as="span" variant="headingMd">
                    {feature}
                  </Text>
                </List.Item>
              ))}
            </List>
          </div>

          <div style={{ minHeight: "60px", width: "20rem" }}>
            <TextField
              label="appreciate you answer"
              value={text}
              onChange={handleTextChange}
              multiline={5}
              autoComplete="off"
              disabled={sent}
              minLength={10}
            />
          </div>
          <Placeholder width="10px" height="20px" />
          <Button size="large" variant={"primary"} disabled={text.length < 5} onClick={sendHandler}>
            Send
          </Button>
        </BlockStack>
      </Box>
    </Card>
  )
}

const PricePlan: React.FC<Props> = ({ name, value, trial, period, amount, features, special, handler, planPair: { plan, usedTrialDays } }) => {
  const restDays = Math.max(trial - usedTrialDays, 0)
  const effFeatures = features
    .slice(0, 2)
    .concat([`${restDays} days trial`])
    .concat(features.slice(2))

  return (
    <Card padding="1200">
      <BlockStack align="center" inlineAlign="center">
        <Text as="span" variant="headingXl" alignment="center">
          {name}
        </Text>
        <Placeholder width="10px" height="20px" />

        <div style={{ minHeight: "160px" }}>
          <List>
            {effFeatures.map((feature: string, idx: number) => (
              <List.Item key={`${value}_${idx}`}>
                <Text as="span" variant="headingMd">
                  {feature}
                </Text>
              </List.Item>
            ))}
          </List>
        </div>

        <div style={{ minHeight: "80px" }}>
          <InlineStack align="center" gap="100" blockAlign="center">
            <Text variant="headingLg" fontWeight="medium" as="span">
              $
            </Text>
            <Text variant="headingXl" fontWeight="bold" as="span">
              {amount}
            </Text>
            <Text variant="headingLg" fontWeight="medium" as="span">
              /{period}
            </Text>
          </InlineStack>
          {special ? (
            <span style={{}}>
              <InlineStack align="center" gap="100" blockAlign="center">
                <Text variant="headingSm" fontWeight="medium" as="span">
                  ~ $
                </Text>
                <Text variant="headingSm" fontWeight="bold" as="span">
                  3.3
                </Text>
                <Text variant="headingSm" fontWeight="medium" as="span">
                  /month
                </Text>
              </InlineStack>
            </span>
          ) : null}
        </div>
        <Placeholder width="10px" height="20px" />
        <Button disabled={value == plan} size="large" variant={special ? "primary" : "secondary"} onClick={() => handler()}>
          Start trial
        </Button>
      </BlockStack>
    </Card>
  )
}

const planBasic = { name: "Basic", value: "basic", trial: 5, period: "month", amount: 5, features: ["all features", "updates", "support"] }
const planScale = {
  name: "Scale",
  value: "scale",
  trial: 10,
  period: "year",
  amount: 39,
  features: ["all features", "updates", "priority support", "fixed price"],
  special: "true",
}

const SubscriptionPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const nav = useNavigate()

  const [planPair, setPlanPair] = useState<PlanData>({ plan: "", usedTrialDays: 100 })

  useEffect(() => {
    shopify.loading(true)
    fetch(`/api/currentPlan?store=${Const.Store}`)
      .then(response => response.json())
      .then(([plan, days]) => {
        //console.log("currentPlan", plan, days)
        setPlanPair({ plan, usedTrialDays: days })
      })
      .finally(() => shopify.loading(false))
  }, [])

  const trialHandler = (plan: string, store: string) => () => {
    //console.log(plan)
    serverClient.chooseSubscriptionQuery(Const.Store, plan).then(({ data, status }) => {
      if (status == 200) {
        open(data, "_top")
      }
    })
  }

  const backHandler = () => {
    dispatch(goMenu({}))
    nav("/")
  }

  const store = Const.Store //userEntity?.store

  return (
    <Page title="Manage subscriptions" backAction={planPair.plan != "" ? { content: "menu", onAction: () => backHandler() } : null}>
      <BlockStack align="center" inlineAlign="center">
        <Text as="span" variant="headingLg" alignment="center">
          Please select your subscription plan
        </Text>
        <Placeholder width="10px" height="40px" />
        <InlineStack align="space-around" gap="1600">
          <PricePlan {...{ ...planBasic }} handler={trialHandler("basic", store)} planPair={planPair} />
          <PricePlan {...{ ...planScale }} handler={trialHandler("scale", store)} planPair={planPair} />
          <GoodbuyPlan />
        </InlineStack>
      </BlockStack>
    </Page>
  )
}

export default SubscriptionPage
