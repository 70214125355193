import { Icon, InlineStack } from "@shopify/polaris"
import { ChevronDownIcon, ChevronRightIcon } from "@shopify/polaris-icons"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { UserEntity } from "@src/datatypes"
import { TreeData } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import clsx from "clsx"
import _ from "lodash"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { NodeApi, NodeRendererProps, Tree, TreeApi } from "react-arborist"
import { OpenMap } from "react-arborist/dist/module/state/open-slice"
import { FillFlexParent } from "./fill-flex-parent"
import { Placeholder } from "./Placeholder"
import styles from "./TreeComponent.module.css"
import { MetaContext } from "@src/context/MetaWrap"

function sortData(data: TreeData[]) {
  function sortIt(data: TreeData[]) {
    data.sort((a, b) => (a.field < b.field ? -1 : 1))
    data.forEach(d => {
      if (d.children) sortIt(d.children)
    })
    return data
  }
  return sortIt(data)
}

const INDENT_STEP = 20

const Node = (x: NodeRendererProps<TreeData>) => {
  const { node, style, dragHandle } = x
  // const xstyle = { ...style, border: "2px solid red" }
  //console.log("node", node, style)
  const indentSize = Number.parseFloat(`${style.paddingLeft || 0}`)

  const sum = _.sum(node.data.fields?.map(e => e.length)) || 0
  const count = node.data.fields?.length || 0
  const effChars = Math.floor(sum > 200 ? (sum - 200) / count : 20)
  //console.log(sum, count, effChars)

  return (
    <div ref={dragHandle} style={style} className={clsx(styles.node, node.state)} onClick={() => node.isInternal && node.toggle()}>
      <div className={styles.indentLines}>
        {new Array(indentSize / INDENT_STEP).fill(0).map((_, index) => {
          return <div key={index}></div>
        })}
      </div>
      <FolderArrow node={node} />
      {/* <Icon className={styles.icon} />  */}

      <div className={styles.nodeInfo}>
        <p className={styles.text}>
          {node.data.entity} {node.data.temporal ? "tmp" : ""}
        </p>
        <p className={styles.textMini}>{node.data.field}</p>
      </div>

    </div>
  )
}

const TreeComponent: React.FC<any> = ({ }) => {
  const [tree, setTree] = useState<TreeApi<TreeData> | null | undefined>(null)
  const [data, setData] = useState<TreeData[]>([])
  const [opens, setOpens] = useState<OpenMap>(null)
  const [name, setName] = useState("")

  const { userEntity } = useContext(UserEntityContext)

  const dispatch = useAppDispatch()
  const metaCtx = useContext(MetaContext)

  const treeData = useAppSelector(state => state.tree.treeData)
  const fieldsPath = useAppSelector(state => state.tree.fieldsPath)

  useEffect(() => {
    /*const enrichFields = (td: TreeData) => {
      const fields = qlFields[td.field] // todo nameKind
      return fields ? { ...td, fields } : td
    }
    const effTreeData = mapTreeData(treeData, enrichFields)*/

    const treeDataArr = treeData ? [treeData] : []

    //console.log("tree data update", effTreeData)
    //const newOpens = treeData?.field ? { [treeData.field]: true } : null

    setData(treeDataArr)
    //setOpens(newOpens)
  }, [treeData])

  const saveHandler = useCallback(() => {
    const data: UserEntity = {
      store: userEntity.store,
      kind: "QL," + userEntity.path[0],
      path: [JSON.stringify({ ...treeData, label: name })],
      star: false,
      starId: -1,
      filters: [],
      fields: { opened: false, fields: [] as any },
      exists: false,
      hasSubscription: false,
      apiVersion: "",
      isQlAdd: true,
    }

    serverClient.saveUserEntityQuery(data)
  }, [treeData, name])

  const getFieldsPath = (node: NodeApi<TreeData>): string[] => {
    if (node.parent) {
      return getFieldsPath(node.parent).concat([node.data.field])
    } else return []
  }


  const activateHandler = (node: NodeApi<TreeData>) => {
    const path = getFieldsPath(node)

    dispatch(metaCtx.control.navigateNode(path))
    //console.log("path", path)
  }

  const handleNameChange = (value: string) => setName(value)

  const handleClearClick = () => setName("")

  const saveDisabled = name == "" || _.isEmpty(data[0]?.children)

  return (
    <div className="scrollable" style={{ padding: "0 10px 0 0" }}>
      {/* <Box paddingInlineStart="400" padding="100">
        <InlineStack gap="100">
          <Button disabled={saveDisabled} onClick={saveHandler}>
            Save
          </Button>
          <TextField
            label=""
            placeholder="query name"
            labelHidden={true}
            value={name}
            onChange={handleNameChange}
            clearButton
            onClearButtonClick={handleClearClick}
            autoComplete="off"
          />
        </InlineStack>
      </Box> */}
      <InlineStack>
        <Placeholder width="20px" />
        <div className={styles.treeContainer}>
          <FillFlexParent>
            {dimens => (
              <Tree
                {...dimens}
                className={styles.tree}
                rowClassName={styles.row}
                ref={t => setTree(t)}
                data={data}
                padding={15}
                rowHeight={70}
                indent={INDENT_STEP}
                overscanCount={8}
                disableDrag={true}
                disableDrop={true}
                openByDefault={true}
                // onClick={evt => evt.preventDefault()}
                onActivate={activateHandler}
              // onToggle={node => console.log("toggle", node)}
              >
                {Node}
              </Tree>
            )}
          </FillFlexParent>
        </div>
      </InlineStack>
      <Placeholder height="40px" />
    </div>
  )
}

export default TreeComponent

function FolderArrow({ node }: { node: NodeApi<TreeData> }) {
  return (
    <span className={styles.arrow}>
      {node.isInternal ? node.isOpen ? <Icon source={ChevronDownIcon} /> : <Icon source={ChevronRightIcon} /> : null}
    </span>
  )
}
