import { Button, OptionList, Popover } from "@shopify/polaris"
import { ExportIcon } from "@shopify/polaris-icons"
import { OptionDescriptor } from "@shopify/polaris/build/ts/src/types"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { findTask, hasCsvTaskCompleted, hasJsonTaskCompleted } from "@src/hooks/useExportTasks"
import { startNewTask } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import MetaCtx from "@src/services/MetaCtx"
import { serverClient } from "@src/services/ServerClient"
import fetchCsvStream from "@src/services/fetchCsvStream"
import fetchJson from "@src/services/fetchJson"
import React, { useCallback, useContext, useState } from "react"

const ExportPopover: React.FC<any> = (props: { fields: string[]; metaCtx: MetaCtx }) => {
  const [popoverActive, setPopoverActive] = useState(false)

  const toggleActive = useCallback(() => setPopoverActive(popoverActive => !popoverActive), [])

  const dispatch = useAppDispatch()
  const { fieldsPath } = useContext(ResolvedUrlContext)
  const { userEntity } = useContext(UserEntityContext)

  const currTasks = useAppSelector(state => state.basic.currTasks)
  const running = useAppSelector(state => state.basic.running)

  const selectionChange = (value: any) => {
    if (value == "export-csv") {
      setPopoverActive(false)
      serverClient
        .exportCsvQuery(userEntity.store, fieldsPath, props.fields, "csv", props.metaCtx.apiVersion)
        .then(r => dispatch(startNewTask({ fieldsPath, running: true })))
        .catch(e => shopify.toast.show("Export error, please contact support"))
    } else if (value == "export-json") {
      setPopoverActive(false)
      serverClient
        .exportCsvQuery(userEntity.store, fieldsPath, props.fields, "json", props.metaCtx.apiVersion)
        .then(r => dispatch(startNewTask({ fieldsPath, running: true })))
        .catch(e => shopify.toast.show("Export error, please contact support"))
    } else if (value == "download-csv") {
      const task = findTask(currTasks, "COMPLETED", "csv")
      task && fetchCsvStream(props.metaCtx, task.url, fieldsPath, task.fields)
    } else if (value == "download-json") {
      const task = findTask(currTasks, "COMPLETED", "json")
      task && fetchJson(props.metaCtx, task.url, fieldsPath, task.fields)
    }
  }

  const activator = <Button onClick={toggleActive} disclosure={true} disabled={false} icon={ExportIcon} />

  //const anyTaskStarted = hasAnyTaskStarted(currTasks)
  const csvTaskCompleted = hasCsvTaskCompleted(currTasks)
  const jsonTaskCompleted = hasJsonTaskCompleted(currTasks)

  const options: OptionDescriptor[] = [
    {
      value: "export-csv",
      label: "Export CSV",
      disabled: running,
    },
    {
      value: "export-json",
      label: "Export JSONL",
      disabled: running,
    },
    csvTaskCompleted && {
      value: "download-csv",
      label: "Download CSV",
    },
    jsonTaskCompleted && {
      value: "download-json",
      label: "Download JSONL",
    },
  ].filter(Boolean)

  const exportSelectedOptions: string[] = []

  return (
    <Popover active={popoverActive} activator={activator} onClose={toggleActive}>
      <Popover.Pane>
        <OptionList onChange={selectionChange} options={options} selected={exportSelectedOptions} />
      </Popover.Pane>
    </Popover>
  )
}

export default ExportPopover
