import { EntityNField2, PageInfo } from "../datatypes"
import { TreeData } from "../reducers/basicSlice"
import _, { default as lodash } from "lodash"

export const getPageInfo = (data: any, depth: number): PageInfo | undefined => {
  if (!data) return undefined
  //let found = Object.entries(data).map(([k, v]: [string, any]) => (k == "pageInfo" ? v : undefined))
  const found = data["pageInfo"] as PageInfo

  if (found) return found
  else if (depth > 4) return undefined
  else {
    const result = Object.entries(data)
      .map(([k, v]: [string, any]) => getPageInfo(v, depth + 1))
      .find((e: any) => e && lodash.isObject(e))

    return result ? result : undefined
  }
}

export const removePageInfo = (data: any): any => {
  if (lodash.isEmpty(data)) return data

  if (lodash.isArray(data)) return data.map(e => removePageInfo(e))

  if (!lodash.isObject(data)) return data

  const { pageInfo: _, ...rest } = data as any

  const entries = Object.entries(rest).filter(([k, v]) => k != "pageInfo")
  const clean = entries.map(([k, v]) => [k, removePageInfo(v)])
  return Object.fromEntries(clean)
}

export const extractName = (name: string): string => name?.replace(".myshopify.com", "")

export const findNodeByPath = (tree: TreeData, path: string[]): TreeData => {
  if (path.length == 0) return tree //{ ...tree, temporal: false }
  else if (tree.field == path[0] && path.length == 1) {
    return tree //{ ...tree, temporal: false }
  } else if (tree.field == path[0]) {
    const tail = path.slice(1)
    const found = tree?.children?.map(c => [c, findNodeByPath(c, tail)]).find(e => e[1]) || []
    /*if (found[1]) {
      found[0].temporal = false
      found[1].temporal = false
    }*/
    return found[1] || null
  } else null
}

const extendTrees = (trees: TreeData[], enfs: EntityNField2[]): TreeData[] => {
  if (enfs.length == 0) return trees //?.map(t => cutTemporalChildren(t))

  const head = enfs[0]

  const foundChild = (trees || []).find(childTree => childTree.field == head.field)
  const effFoundChild = extendTree(foundChild, enfs.slice(1))

  if (effFoundChild) {
    const newChildren = (trees || []).map(childTree => {
      if (childTree.field == head.field) {
        return effFoundChild
      } else return childTree
    })
    //console.log("newch", newChildren, effFoundChild)

    return newChildren
  } else {
    const newChild = createTreeData(head, true)
    const effChild = extendTree(newChild, enfs.slice(1))

    return (trees || []).concat(effChild)
  }
}

// run for copy of tree data
export const extendTree = (tree: TreeData, enfs: EntityNField2[]): TreeData => {
  if (enfs.length == 0) return tree //cutTemporalChildren(tree)

  const head = enfs[0]

  if (tree.field == head.field) {
    return { ...tree, children: extendTrees(tree.children, enfs.slice(1)), enf: head } as TreeData
  } else if (enfs.length > 0) {
    const nextHead = enfs[0]

    // some non existing node found
    const newChild = createTreeData(nextHead, true)
    const existFields = (tree.children || []).map((e: any) => e.field)
    const effChild = extendTree(newChild, enfs.slice(1))
    if (existFields.includes(effChild.field)) {
      return tree
    } else {
      const tmp = { ...tree, children: (tree.children || []).concat([effChild]), enf: nextHead }
      return tmp
    }
  } else return tree
}

export const cutTemporalChildren = (tree: TreeData) => {
  if (!tree) return tree

  const hasChildren = !_.isEmpty(tree.children)
  const allTemporal = (tree.children || []).every(e => e.temporal)
  if (hasChildren && allTemporal) return { ...tree, children: undefined } as TreeData
  else {
    const newChildren: TreeData[] = tree?.children?.map(c => cutTemporalChildren(c))
    return { ...tree, children: newChildren }
  }
}

export const createTreeData = (enf: EntityNField2, temporal: boolean): TreeData => ({
  id: enf.field,
  field: enf.field,
  entity: enf.entity,
  temporal,
  enf,
})

export const mapTreeData = (tree: TreeData, modifier: (item: TreeData) => TreeData): TreeData => {
  const children = tree.children ? tree.children.map(e => mapTreeData(e, modifier)) : undefined

  return { ...modifier(tree), children }
}

export const mergeFold = (obj: any, list: any[]): any => _.reduce(list, (r, v, k) => _.merge(r, v), obj)

export const collectFields = (tree: TreeData): any => {
  const mainObj = (tree.field && !_.isEmpty(tree.fields) && { [tree.field]: tree.fields }) || {}

  const childrenFields = (tree.children || []).map(e => collectFields(e)).filter(e => !_.isEmpty(e))
  return mergeFold(mainObj, childrenFields)
}
