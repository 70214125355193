import { Button, ButtonGroup, Divider, Icon, InlineGrid, InlineStack, OptionList, Tooltip } from "@shopify/polaris"
import { QuestionCircleIcon } from "@shopify/polaris-icons"
import { Entity } from "@src/datatypes"
import { useDimensions } from "@src/hooks/useDimensions"
import Const from "@src/logic/const"
import React, { useRef } from "react"

interface Props {
  entity: Entity
  isQlAdd: boolean
  selectedFields: string[]
  handleSelectionChange: (selection: any, isQlAdd: boolean) => unknown
  loading: boolean
}

const FieldsBlock: React.FC<Props> = ({ entity, handleSelectionChange, isQlAdd, selectedFields, loading }) => {
  const ref = useRef(null)
  const { width, height } = useDimensions(ref)

  const fieldsAmount = entity?.fields.length || 0

  const options =
    entity?.fields.map(f => ({
      value: f.name,
      label: f.name,
      media: (
        <Tooltip content={f.desc}>
          <Icon source={QuestionCircleIcon} tone="interactive" />
        </Tooltip>
      ),
    })) || []
  const splitIdx = Math.ceil(options.length / 2)

  const options0 = options.splice(0, splitIdx)
  const options1 = options.splice(-splitIdx)

  const selectAllHandler = () =>
    handleSelectionChange(
      entity.fields.map(e => e.name),
      isQlAdd
    )

  const deselectAllHandler = () => handleSelectionChange([], isQlAdd)

  return (
    <>
      <div style={{ padding: "10px 0" }} ref={ref}>
        <Divider />
      </div>

      {fieldsAmount > Const.FieldsNoSelectAllAmount ? (
        <InlineStack align="end">
          <ButtonGroup variant="segmented">
            <Button variant="plain" onClick={selectAllHandler}>
              Select all
            </Button>
            <Button variant="plain" onClick={deselectAllHandler}>
              Deselect all
            </Button>
          </ButtonGroup>
        </InlineStack>
      ) : null}

      {width > 350 ? (
        <InlineGrid columns={2}>
          <OptionList onChange={value => handleSelectionChange(value, isQlAdd)} options={options0} selected={selectedFields} allowMultiple />
          <OptionList onChange={value => handleSelectionChange(value, isQlAdd)} options={options1} selected={selectedFields} allowMultiple />
        </InlineGrid>
      ) : (
        <InlineGrid columns={1}>
          {loading ? null : (
            <OptionList
              onChange={value => handleSelectionChange(value, isQlAdd)}
              options={[].concat(options0).concat(options1)}
              selected={selectedFields}
              allowMultiple
            />
          )}
        </InlineGrid>
      )}
    </>
  )
}

export default FieldsBlock
