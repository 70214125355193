import { InlineStack, Link, Text } from "@shopify/polaris"
import { CurrentStateContext } from "@src/context/CurrentStateWrap"
import { MetaContext } from "@src/context/MetaWrap"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { resolveChildren } from "@src/logic/treeResolve"
import { collectIdLinks } from "@src/pages/SchemeWalk"
import _ from "lodash"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"

interface Props {}

const Breadcrumb: React.FC<Props> = ({}) => {
  const nav = useNavigate()
  const metaCtx = useContext(MetaContext)
  const { fieldsPath, entity, updateResolvedUrlState } = useContext(ResolvedUrlContext)
  const userEntity = useContext(UserEntityContext)
  const { flushState } = useContext(CurrentStateContext)

  const links = collectIdLinks(fieldsPath).reverse()
  const currentField = _.last(fieldsPath)

  return (
    <InlineStack gap={"200"}>
      {links.map((paths: string[], i: number) => {
        const path = paths.join("/")
        const linkFieldsPath = [...paths]
        const lastField = _.last(linkFieldsPath)

        const clickHandler = () => {
          const fieldsEnfs = resolveChildren(metaCtx, linkFieldsPath)
          const result = updateResolvedUrlState(fieldsEnfs, true)

          if (result.path) {
            flushState()
            nav(`/${result.path}`)
          }
        }

        return (
          <Link monochrome={true} key={path} onClick={clickHandler}>
            <Text as="h4" variant="headingMd">
              {lastField}
            </Text>
          </Link>
        )
      })}
      <Text as="h4" variant="headingMd">
        /{currentField}
      </Text>
      {/* {fieldsPath.join(",")} */}
      {/* entity:{entity.name} ue:{userEntity.path.join(",")}  */}
    </InlineStack>
  )
}

export default Breadcrumb
