const devSettings = {
  previewOn: false, // 'preview on load' && 'show error in entities list'
  forcePlay: false,

  testMode: false,
  powerMode: true,
  log: false,
}

export default devSettings
