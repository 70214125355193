import { Task } from "@src/datatypes"
import Const from "@src/logic/const"
import { setTasksState, startNewTask } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { useEffect } from "react"
import { useInterval } from "./useInterval"

const useExportTasks = () => {
  const dispatch = useAppDispatch()

  const running = useAppSelector(state => state.basic.running)
  const currTasks = useAppSelector(state => state.basic.currTasks)
  const lastTime = useAppSelector(state => state.basic.time)

  const getInitTasks = () =>
    fetch(`/api/tasks?store=${Const.Store}`)
      .then(response => response.json())
      .then(tasks => {
        if (!!tasks) {
          dispatch(setTasksState({ tasks: tasks[0], time: tasks[1] }))
        }
        return tasks
      })

  const tickHandler = () =>
    fetch(`/api/freshTasks?store=${Const.Store}&time=${lastTime}`)
      .then(response => response.json())
      .then(tasks => {
        //console.log('handler works', freshTask)
        if (!!tasks) {
          dispatch(setTasksState({ tasks: tasks[0], time: tasks[1] }))
        }
      })

  useEffect(() => {
    if (running) {
      //console.log("job-cycle start due to newTask")
      getInitTasks()
    }
  }, [running])

  useInterval(
    () => {
      const created = currTasks.find(t => t.status == "CREATED")

      if (!created) {
        //console.log("stopped job", currTasks)
        dispatch(startNewTask({ fieldsPath: [], running: false }))
      } else tickHandler()
    },
    running ? 5000 : null
  )
}

export default useExportTasks

export const hasAnyTaskStarted = (tasks: Task[]) => !!tasks.find(t => t.status == "CREATED")

export const hasCsvTaskCompleted = (tasks: Task[]) => hasTaskStatus(tasks, "COMPLETED", "csv")
export const hasJsonTaskCompleted = (tasks: Task[]) => hasTaskStatus(tasks, "COMPLETED", "json")
export const hasCsvTaskCreated = (tasks: Task[]) => hasTaskStatus(tasks, "CREATED", "csv")
export const hasJsonTaskCreated = (tasks: Task[]) => hasTaskStatus(tasks, "CREATED", "json")

export const findTask = (tasks: Task[], status: string, format: string) => tasks.find(t => t.status == status && t.format == format)
export const hasTaskStatus = (tasks: Task[], status: string, format: string) => !!findTask(tasks, status, format)
