import {
  CollectionIcon,
  DiscountIcon,
  InventoryIcon,
  LocationIcon,
  MetaobjectIcon,
  OrderDraftIcon,
  OrderIcon,
  PackageFulfilledIcon,
  PersonIcon,
  ProductIcon,
  VariantIcon,
  WorkIcon,
} from "@shopify/polaris-icons"
import React from "react"

interface Description {
  entityId: number
  entity: string
  field: string
  title: string
  icon: React.FC<any>
}

interface IMeta {
  [kind: string]: Description
}

const Meta: IMeta = {
  companies: {
    entityId: 1389,
    entity: "CompanyConnection",
    field: "companies",
    title: "Companies",
    icon: WorkIcon,
  },
  collections: {
    entityId: 1425,
    entity: "CollectionConnection",
    field: "collections",
    title: "Collections",
    icon: CollectionIcon,
  },
  customers: {
    entityId: 1334,
    entity: "CustomerConnection",
    field: "customers",
    title: "Customers",
    icon: PersonIcon,
  },
  fulfillmentOrders: {
    entityId: 1016,
    entity: "FulfillmentOrderConnection",
    field: "fulfillmentOrders",
    title: "Fulfillment Orders",
    icon: PackageFulfilledIcon,
  },
  inventoryItems: {
    entityId: 925,
    entity: "InventoryItemConnection",
    field: "inventoryItems",
    title: "Inventory Items",
    icon: InventoryIcon,
  },
  locations: {
    entityId: 883,
    entity: "LocationConnection",
    field: "locations",
    title: "Locations",
    icon: LocationIcon,
  },
  orders: {
    entityId: 704,
    entity: "OrderConnection",
    field: "orders",
    title: "Orders",
    icon: OrderIcon,
  },
  productVariants: {
    entityId: 502,
    entity: "ProductVariantConnection",
    field: "ProductVariants",
    title: "Product Variants",
    icon: VariantIcon,
  },
  products: {
    entityId: 554,
    entity: "ProductConnection",
    field: "products",
    title: "Products",
    icon: ProductIcon,
  },
  draftOrders: {
    entityId: 1080,
    entity: "DraftOrderConnection",
    field: "draftOrders",
    title: "Draft Orders",
    icon: OrderDraftIcon,
  },
  codeDiscountNodes: {
    entityId: 1147,
    entity: "DiscountCodeNodeConnection",
    field: "codeDiscountNodes",
    title: "Discount Codes",
    icon: DiscountIcon,
  },
  /*giftCards: { // private apps only
    entityId: 937,
    field: "giftCards",
    title: "Gift Cards",
    icon: GiftCardIcon,
  },
  inventoryProperties: { // no data
    entityId: 890,
    field: "inventoryProperties",
    title: "Inventory Properties",
    icon: InventoryUpdatedIcon,
  },*/
  metaobjectDefinitions: {
    entityId: 747,
    entity: "MetaobjectDefinitionConnection",
    field: "metaobjectDefinitions",
    title: "Metaobject Definitions",
    icon: MetaobjectIcon,
  },
  /*collectionRulesConditions: { // no data
    entityId: 1370,
    field: "collectionRulesConditions",
    title: "Collection Rules Conditions",
    icon: CollectionFeaturedIcon,
  },*/
  // discountNodes: {
  //   entityId: 1088,
  //   field: "discountNodes",
  //   title: "Discount Nodes",
  //   icon: DiscountIcon,
  // },
}

export default Meta
