import { indentWithTab } from "@codemirror/commands";
import { json } from "@codemirror/lang-json";
import { Compartment, EditorState } from '@codemirror/state';
import { EditorView, keymap } from "@codemirror/view";
import { basicSetup } from 'codemirror';
import React, { useEffect, useRef, useState } from "react";

const CodeComponent: React.FC<any> = ({ text }) => {

    const editor = useRef()
    const [doc, setDoc] = useState('')
    const [hasChanges, setHasChanges] = useState(false)
    const viewRef = useRef(null)

    function doFormat() {

        /*const { state } = viewRef.current;
        const formatted = format(state.doc.toString(), {
            parser: "babel",
            plugins: [parserBabel],
            semi: false, singleQuote: true, printWidth: 200
        })
        setDocHandler(formatted)*/
    }

    function setDocHandler(txt: string) {
        if (!viewRef.current) return

        viewRef.current.dispatch({
            changes: { from: 0, to: viewRef.current.state.doc.length, insert: txt }
        })
    }

    useEffect(() => {
        //if (!login.email) return
        //console.log(`code page ${script} ${login.email}`)

        let readonly = new Compartment

        const startState = EditorState.create({
            doc,
            extensions: [
                json(),
                basicSetup,
                keymap.of([indentWithTab]),
                //gruvboxDark,
                EditorState.readOnly.of(true),
                EditorView.updateListener.of((update) => {
                    if (update.changes) {
                        setDoc(update.state.doc.toString())
                    }
                    // https://discuss.codemirror.net/t/codemirror-6-proper-way-to-listen-for-changes/2395/5
                    setHasChanges(update.docChanged)
                }),
                // https://github.com/enso-org/enso/blob/64490ec0af15161903ee2fddbae6e1ef4bea41c6/app/gui2/stories/histoire/HstCode.vue#L46
                /*foldGutter({

                })*/
            ],
        });

        const view = new EditorView({
            state: startState, parent: editor.current
        })
        /*view.dispatch({
            effects: readonly.reconfigure(EditorState.readOnly.of(true))
        })*/
        
        viewRef.current = view
        //document.title = 'HS.code'


        return () => {
            view.destroy()

        }
    }, [])

    useEffect(() => {
        setDocHandler(text)
    }, [text, viewRef])

    const style = {
        maxHeight: 'calc(100vh - 150px)',
        height: '100%'
    }

    return (
        <div className="cm-wrap cm-scroller">
            <div className="CodeMirror" style={style} ref={editor} />
        </div>
    )
}

export default CodeComponent;