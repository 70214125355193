import { Button, ButtonGroup, Card, InlineStack, OptionList, Page, TabProps, Tabs } from "@shopify/polaris"
import { Placeholder } from "@src/components/Placeholder"
import Scopes from "@src/logic/Scopes"
import Const from "@src/logic/const"
import devSettings from "@src/logic/devSettings"
import { scopesBySections } from "@src/logic/scopesResolve"
import { goMenu } from "@src/reducers/basicSlice"
import { useAppDispatch } from "@src/reducers/hooks"
import { loadApiVersion } from "@src/services/MetaCtx"
import { serverClient } from "@src/services/ServerClient"
import _ from "lodash"
import React, { useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

const ScopesPage: React.FC = () => {
  const dispatch = useAppDispatch()
  const nav = useNavigate()

  const [advScopes, setAdvScopes] = useState<string[]>([])
  const [basicSelected, setBasicSelected] = useState<string[]>([])
  const [advSelected, setAdvSelected] = useState<string[]>([])
  const [selectedTab, setSelectedTab] = useState(0)

  const store = Const.Store

  const apiVersion = loadApiVersion()
  useEffect(() => {
    serverClient.runPreviewQuery("", store, apiVersion, ["orders"], false).then(preview => {
      devSettings.log && console.log(preview)
      const tmp = preview?.scopes?.split(",") || []
      setAdvScopes(tmp)
      setAdvSelected(tmp)

      const initBasic = Object.entries(Scopes)
        .filter(([k, v]) => _.intersection(v, tmp).length > 0)
        .map(([k, v]) => k)
      setBasicSelected(initBasic)

      //console.log("foo", _.intersection(tmp, Scopes["Metaobjects and Locations"]))
    })
  }, [])

  const backHandler = () => {
    dispatch(goMenu({}))
    nav("/")
  }

  const askPermissionHandler = (set: any[]) => {
    if (set.length == 0) {
      //console.log("scopes are empty")
      return
    }
    serverClient.runAskPermissionQuery(store, set, true).then(url => {
      if (!url) return
      open(url, "_top")
    })
  }

  const sections = scopesBySections(advScopes) //currScopes.map(scope => ({ value: scope, label: scope }))
  const basicOptions = Object.keys(Scopes).map(v => ({ value: v, label: v }))

  const basicScopesSelected = Object.entries(Scopes)
    .filter(([k, v]) => basicSelected.includes(k))
    .flatMap(([k, v]) => v)

  //const basicChanges =
  const hasBasicChanges = _.xor(advScopes, basicScopesSelected).length > 0 && basicScopesSelected.length > 0
  const hasAdvChanges = _.xor(advScopes, advSelected).length > 0 && advSelected.length > 0

  const tabs: TabProps[] = [
    {
      id: "basic",
      content: "Basic",
      accessibilityLabel: "Basic",
      panelID: "basic",
    },
    {
      id: "advanced",
      content: "Advanced",
      panelID: "advanced",
    },
  ]

  const handleTabChange = useCallback((tab: number) => setSelectedTab(tab), [])

  const applyDisabled = (selectedTab == 0 && !hasBasicChanges) || (selectedTab == 1 && !hasAdvChanges)

  return (
    <Page title="Manage permission scopes" backAction={{ content: "menu", onAction: () => backHandler() }}>
      <div className="scrollable" style={{ padding: "0 0px 0 0" }}>
        <Card>
          <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} fitted>
            {selectedTab == 0 ? <OptionList options={basicOptions} selected={basicSelected} onChange={setBasicSelected} allowMultiple /> : null}
            {selectedTab == 1 ? <OptionList sections={sections} selected={advSelected} onChange={setAdvSelected} allowMultiple /> : null}
          </Tabs>

          <InlineStack align="end">
            <ButtonGroup>
              <Button onClick={() => askPermissionHandler(selectedTab == 0 ? basicScopesSelected : advSelected)} disabled={applyDisabled}>
                Apply changes
              </Button>
            </ButtonGroup>
          </InlineStack>
          {/* <p>basicSelected: {basicSelected.join(" ")}</p>
          <p>scopes: {advScopes.join(" ")}</p>
          <p>basicScopesSelected: {basicScopesSelected.join(" ")}</p>
          <p>advScopes: {advScopes.join(" ")}</p>
          <p>diff: {_.xor(advScopes, basicScopesSelected).join(" ")}</p> */}
        </Card>
        <Placeholder height="20px" />
      </div>
    </Page>
  )
}

export default ScopesPage
