import { EntityNField, EntityNField2 } from "@src/datatypes"
import { asEntityFields, getResolvedChildren } from "@src/logic/treeResolve"
import { findEntityByName } from "@src/pages/SchemeWalk"
import MetaCtx from "@src/services/MetaCtx"
import _ from "lodash"

export const resolveEntities = (metaCtx: MetaCtx, fieldsEnfs: EntityNField[]) => {
  const fieldsPath = asEntityFields(fieldsEnfs)

  const entityName = _.last(fieldsEnfs).entity
  const entity = findEntityByName(metaCtx.data, entityName)

  const entities0: EntityNField[] = getResolvedChildren(metaCtx, fieldsPath) || []

  const entities: EntityNField2[] = entities0.map((e: EntityNField) => ({
    ...e,
    entityTypes: metaCtx.getEntityByName(e.entity).entityTypes,
  }))

  return { entity, entities }
}
