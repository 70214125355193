import { TreeData } from "@src/reducers/basicSlice"
import Meta from "@src/services/Meta"
import MetaCtx from "@src/services/MetaCtx"
import { resolveChildren } from "./treeResolve"
import { resolveEntities } from "@src/reducers/StateResolver"
import { selectTreeNode, startNewQl } from "@src/reducers/treeSlice"

class Control {
  metaCtx: MetaCtx
  constructor(metaCtx: MetaCtx) {
    this.metaCtx = metaCtx
  }

  startRoot = (rootField: string) => {
    const meta = Meta[rootField]
    const root = { id: meta.field, field: meta.field, entity: meta.entity } as TreeData
    const enfs = resolveChildren(this.metaCtx, [rootField])
    const { entity, entities } = resolveEntities(this.metaCtx, enfs)
    //console.log("enfs", enfs)
    //console.log("new root", root)
    //console.log("entities", entities)
    return startNewQl({ root, entities })
  }

  navigateNode = (path:string[])=> {
    const enfs = resolveChildren(this.metaCtx, path)
    const { entity, entities } = resolveEntities(this.metaCtx, enfs)

    return selectTreeNode({path, entities})
  }
}

export default Control
