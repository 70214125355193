import useResolveUrlState, { ResolvedUrlCtxMut } from "@src/hooks/useResolveUrlState"
import React, { createContext, useContext } from "react"
import { MetaContext, WrapProps } from "./MetaWrap"

export const ResolvedUrlContext = createContext<ResolvedUrlCtxMut>(null)

const ResolveUrlWrap: React.FC<WrapProps> = ({ children }) => {
  const metaCtx = useContext(MetaContext)
  const state = useResolveUrlState({ metaCtx })

  return <ResolvedUrlContext.Provider value={state}> {state.resolved && children}</ResolvedUrlContext.Provider>
}

export default ResolveUrlWrap
