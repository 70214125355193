import { Entity, EntityNField, Trim } from "@src/datatypes"
import MetaCtx from "@src/services/MetaCtx"
import _ from "lodash"
import Meta from "../services/Meta"

const MaxConnections = 5
const MaxDepth = 7

const IgnoreEntities = new Set(["App", "Metafield", "MetafieldConnection"])

// walk through all tree branches and leafs and count entities count
export const walkTreeCount = (metaCtx: MetaCtx, kind: string) => {
  const DefaultReturn = 0
  const root = Meta[kind]

  const walkTree = (entityId: number, depth: number, entityPath: string[], nConnections: number, trims: Trim) => {
    const entity = metaCtx.getEntityById(entityId)
    const isConnection = entity.name.endsWith("Connection")

    if (isLimitReached(depth, isConnection, nConnections)) {
      return DefaultReturn
    }

    if (isCycledFunc(entityPath, entity.name, entity.baseEntity)) {
      return DefaultReturn
    }

    if (isTrimExcluded(trims, kind, depth)) {
      return DefaultReturn
    }

    const numbers: any[] = entity.entities
      .filter((e: any) => !IgnoreEntities.has(e.entity))
      .map((childEnf: any) => {
        const entityPfx = entity.baseEntity ? [entity.name, entity.baseEntity] : [entity.name]

        return walkTree(childEnf.id, depth + 1, [...entityPath, ...entityPfx], nConnections + (isConnection ? 1 : 0), childEnf.trims) + 1
      })

    return numbers.length == 0 ? 0 : _.sum(numbers)
  }

  return walkTree(root.entityId, 0, [], 1, [])
}

// similar to getChildren
// from array of fields returns a valid array of entities
export const resolveChildren = (metaCtx: MetaCtx, fields: string[]): EntityNField[] => {
  const DefaultReturn: any[] = []
  const kind = fields[0]
  const root = Meta[kind]

  const walkTree = (fields: string[], entityName: string, depth: number, entityPath: string[], nConnections: number, trims: Trim): EntityNField[] => {
    const entity = metaCtx.getEntityByName(entityName)
    const isConnection = entity.name.endsWith("Connection")

    if (isLimitReached(depth, isConnection, nConnections)) {
      return DefaultReturn
    }

    if (isCycledFunc(entityPath, entity.name, entity.baseEntity)) {
      return DefaultReturn
    }

    if (isTrimExcluded(trims, kind, depth)) {
      return DefaultReturn
    }

    if (fields.length == 0) {
      return DefaultReturn
    } else {
      const childEnf = getChildEnf(entity, fields[0])

      if (childEnf) {
        const entityPfx = entity.baseEntity ? [entity.name, entity.baseEntity] : [entity.name]

        return [childEnf].concat(
          walkTree(fields.slice(1), childEnf.entity, depth + 1, [...entityPath, ...entityPfx], nConnections + (isConnection ? 1 : 0), childEnf.trims)
        )
      } else return DefaultReturn
    }
  }

  const rootEnF = { id: root.entityId, entity: root.entity, field: kind, desc: "", trims: [] } as EntityNField
  if (fields.length == 1) {
    return [rootEnF]
  } else {
    const result = walkTree(fields.slice(1), root.entity, 0, [], 1, [])
    return [rootEnF].concat(result)
  }
}

// get children of resolved fields array
export const getResolvedChildren = (metaCtx: MetaCtx, fields: string[]): EntityNField[] => {
  const DefaultReturn: any = undefined
  if (!fields || fields.length == 0) {
    return DefaultReturn
  }

  const kind = fields[0]
  const root = Meta[kind]

  // return EntityNField[]
  const walkTree = (fields: string[], entityName: string, depth: number, entityPath: string[], nConnections: number, trims: Trim): EntityNField[] => {
    const entity = metaCtx.getEntityByName(entityName)
    const isConnection = entity.name.endsWith("Connection")

    if (isLimitReached(depth, isConnection, nConnections)) {
      return DefaultReturn
    }

    if (isCycledFunc(entityPath, entity.name, entity.baseEntity)) {
      return DefaultReturn
    }

    if (isTrimExcluded(trims, kind, depth)) {
      return DefaultReturn
    }

    if (fields.length == 0) {
      const children = entity.entities
      return children
    } else {
      const childEnf = getChildEnf(entity, fields[0])

      if (childEnf) {
        const entityPfx = entity.baseEntity ? [entity.name, entity.baseEntity] : [entity.name]

        return walkTree(
          fields.slice(1),
          childEnf.entity,
          depth + 1,
          [...entityPath, ...entityPfx],
          nConnections + (isConnection ? 1 : 0),
          childEnf.trims
        )
      } else return DefaultReturn
    }
  }

  return walkTree(fields.slice(1), root.entity, 0, [], 1, [])
}

const getChildEnf = (entity: Entity, childField: string) =>
  entity.entities.filter(e => !IgnoreEntities.has(e.entity)).find(e => e.field == childField)

const isCycledFunc = (entityPath: string[], entityName: string, baseEntityName: string) =>
  entityPath.find(e => e == entityName) || (baseEntityName && entityPath.find(e => e == baseEntityName))

const isLimitReached = (depth: number, isConnection: boolean, nConnections: number) =>
  depth > MaxDepth || (isConnection && nConnections > MaxConnections)

const isTrimExcluded = (trims: Trim, kind: string, depth: number) => trims && trims.find(e => e[0] == kind && depth >= e[1])

export const asEntityFields = (enfs: EntityNField[]) => (!!enfs ? enfs.map(e => e?.field) : [])
