import { Badge, Box, Button, ButtonGroup, Card, Collapsible, Icon, InlineStack, ResourceItem, ResourceList, Text, Tooltip } from "@shopify/polaris"
import { InfoIcon, PlayIcon, QuestionCircleIcon, StarFilledIcon, StarIcon } from "@shopify/polaris-icons"
import { CurrentStateContext } from "@src/context/CurrentStateWrap"
import { MetaContext } from "@src/context/MetaWrap"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { EntityNField, UserEntity } from "@src/datatypes"
import Const from "@src/logic/const"
import { documentLink } from "@src/logic/scopesResolve"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import _ from "lodash"
import React, { useContext } from "react"
import { useNavigate } from "react-router-dom"
import ExportPopover from "./ExportPopover"
import FieldsBlock from "./FieldsBlock"
import { Placeholder } from "./Placeholder"
import { setCustomQlFields } from "@src/reducers/basicSlice"

interface Props {
  loading: boolean
  isFavorite: boolean
  fetchHandler: (fields: string[]) => void
  fetchChildHandler: (field: string, query: string) => void
}

const starIcon = <Icon source={StarIcon} tone="base" />
const starFilledIcon = <Icon source={StarFilledIcon} tone="base" />

const playIcon = <Icon source={PlayIcon} tone="base" />

const FieldsComponent: React.FC<Props> = ({ isFavorite, fetchHandler, fetchChildHandler, loading }) => {
  const nav = useNavigate()
  const dispatch = useAppDispatch()

  const metaCtx = useContext(MetaContext)
  const { userEntity, setStar, setUserEntity } = useContext(UserEntityContext)
  const { entity, fieldsEnfs, updateResolvedUrlState } = useContext(ResolvedUrlContext)
  const { fieldsOpen, selectedFields, setFieldsOpen, setSelectedFields, flushState } = useContext(CurrentStateContext)

  const isQlAdd = useAppSelector(state => state.basic.isQlAdd)
  const qlKind = useAppSelector(state => state.basic.qlKind)

  const handleOpenToggle = (userEntity: UserEntity) => {
    //setFieldsOpen(!fieldsOpen)
    setUserEntity({ ...userEntity, fields: { ...userEntity.fields, opened: !fieldsOpen } })
    saveSelectedFieldsDebounced(userEntity.star, selectedFields, !fieldsOpen, false)
  }

  const saveUserEntity = (star: boolean, fields: string[], opened: boolean, isQlAdd: boolean) => {
    dispatch(setCustomQlFields({ kindName: userEntity.path[0], fields }))

    const data: UserEntity = {
      store: userEntity.store,
      kind: userEntity.path[0],
      path: userEntity.path,
      star,
      starId: -1,
      filters: userEntity.filters,
      fields: { opened, fields },
      exists: false,
      hasSubscription: false,
      apiVersion: "",
      isQlAdd,
    }
    serverClient.saveUserEntityQuery(data)
  }

  // save state to server
  const saveSelectedFieldsDebounced = _.debounce(saveUserEntity, Const.SaveDebounceTime)

  // dont callback
  const handleSelectionChange = (selection: any, isQlAdd: boolean) => {
    setUserEntity({ ...userEntity, fields: { ...userEntity.fields, fields: selection } })

    saveSelectedFieldsDebounced(userEntity.star, selection, fieldsOpen, isQlAdd)
  }

  const starHandler = () => {
    setUserEntity({ ...userEntity, star: !userEntity.star })
    saveSelectedFieldsDebounced(!userEntity.star, selectedFields, fieldsOpen, false)
  }

  const docHandler = () => {
    const doclink = documentLink(entity.name, entity.entityTypes, metaCtx.apiVersion)
    window.open(doclink, "_blank")
  }

  const isFetchEnabled = selectedFields.length > 0
  const fieldsAmount = entity?.fields.length || 0
  const hasFields = fieldsAmount > 0
  const alwaysShow = hasFields && fieldsAmount <= Const.FieldsNoCollapseAmount

  const labelFields = fieldsAmount && `${selectedFields?.length}/${fieldsAmount}`

  //badges
  const isDeprecated = entity?.isDeprecated || false
  const hasBadge = isDeprecated

  const fieldsBlock = <FieldsBlock {...{ entity, isQlAdd, selectedFields, handleSelectionChange, loading }} />

  const isStarDisabled = !userEntity || userEntity.path.length == 1
  const hasUnions = entity.possibleTypes.length > 0

  //const hasCustomQl = qlKind != undefined
  const hideStar = isFavorite || isQlAdd // || hasCustomQl

  //console.log(unionEntities)

  const diveUnionHandler = (entityName: string) => {
    const entity: EntityNField = {
      id: 0,
      entity: entityName,
      field: entityName,
      desc: "empty",
      semType: [],
      trims: [],
      entityTypes: ["union"],
    } as EntityNField
    const newFieldsEnfs = [...fieldsEnfs].concat([entity])

    const result = updateResolvedUrlState(newFieldsEnfs, false)
    if (result.path) {
      flushState()
      nav(`/${result.path}`)
    }
  }

  const unionsComponent = hasUnions ? (
    <>
      <Placeholder height="20px" />
      <ResourceList
        showHeader={false}
        items={entity.possibleTypes as any[]}
        renderItem={item => {
          const media = (
            <Tooltip content={item}>
              <Icon source={QuestionCircleIcon} tone="base" />
            </Tooltip>
          )

          const shortcutActions = [
            true
              ? {
                  content: playIcon,
                  onAction: () => fetchChildHandler(item, "union"),
                }
              : undefined,
          ].filter(Boolean)

          return (
            <ResourceItem id={item} onClick={() => diveUnionHandler(item)} shortcutActions={shortcutActions as any}>
              <Box paddingBlock="200">
                <InlineStack gap="200">
                  {media}
                  <Text variant="bodyLg" fontWeight="regular" as="h2">
                    {item}
                  </Text>
                </InlineStack>
              </Box>
            </ResourceItem>
          )
        }}
      />
    </>
  ) : null

  return (
    <Card>
      <InlineStack blockAlign="stretch" align="space-between">
        {hasFields && !alwaysShow ? (
          <Button
            variant="plain"
            disclosure="down"
            onClick={() => handleOpenToggle(userEntity)}
            ariaExpanded={fieldsOpen}
            ariaControls="basic-collapsible"
          >
            {labelFields} fields
          </Button>
        ) : null}
        <Placeholder />

        {isQlAdd ? null : (
          <InlineStack>
            <ExportPopover fields={selectedFields} metaCtx={metaCtx} />
            <Placeholder width="20px" />

            <ButtonGroup variant="segmented">
              <Button icon={InfoIcon} onClick={docHandler} />
              {hideStar ? null : <Button icon={userEntity.star ? starFilledIcon : starIcon} disabled={isStarDisabled} onClick={starHandler} />}
              <Button icon={PlayIcon} disabled={!isFetchEnabled} onClick={() => fetchHandler(selectedFields)} />
            </ButtonGroup>
          </InlineStack>
        )}
      </InlineStack>
      {hasBadge ? (
        <InlineStack>
          {isDeprecated ? (
            <Badge tone="critical" size="small">
              Deprecated
            </Badge>
          ) : null}
        </InlineStack>
      ) : null}
      {alwaysShow ? fieldsBlock : null}
      {hasFields && !alwaysShow ? (
        <Collapsible id="fields-collaps" open={fieldsOpen} transition={{ duration: "200ms", timingFunction: "ease-in-out" }}>
          {fieldsBlock}
        </Collapsible>
      ) : null}
      {unionsComponent}
    </Card>
  )
}

export default FieldsComponent
