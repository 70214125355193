import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { Entity } from "@src/datatypes"
import { loadApiVersion } from "@src/services/MetaCtx"

export interface MetaState {
  apiVersion: string
  data: Entity[]
}

const initialState: MetaState = {
  apiVersion: loadApiVersion(),
  data: [],
}

export const metaSlice = createSlice({
  name: "meta",
  initialState,
  reducers: {
    setMetaState: (state, action: PayloadAction<any>) => {
      state.data = action.payload.data
      state.apiVersion = action.payload.apiVersion
    },
  },
})

export const { setMetaState } = metaSlice.actions

export default metaSlice.reducer
