import _ from "lodash"
import Scopes from "./Scopes"

const ignoredScopes = new Set<string>(["read_marketplace_fulfillment_orders", "preferences", "read_marketplace_orders"])

const map = {
  orders: [/*"read_all_orders", */ "read_orders"],
  customers: ["read_customers"],
  companies: ["read_customers", "read_companies"],
  draftOrders: ["read_draft_orders"],
  market: ["read_markets"],
  mergeable: ["read_customer_merge"],
  paymentMethods: [
    /*"read_customer_payment_methods"*/
  ] as any[], // https://shopify.dev/docs/api/usage/access-scopes#subscription-apis-permissions shopify should approve
  subscriptionContracts: [
    //"read_own_subscription_contracts" // Oauth error missing_shopify_permission: read_own_subscription_contracts
  ] as any[],
  metaobjectDefinitions: ["read_metaobject_definitions"],
}

export const tokenize = (text: string): string[] => {
  const test0 = _.replace(text, "Access denied for", "")
  const test1 = _.replace(test0, "field", "")
  //const test2 = _.replace(test1, " ", "")
  return test1.match(/\w+/g)
}

const requiredAccessRegex = /`([^`]+)`/g
//const txt = "Access denied for resourcePublicationOnCurrentPublication field. Required access: `read_product_listings` access scope."
const extractQuotesScope = (text: string): string[] => text.match(requiredAccessRegex).map(m => m.slice(1, -1))

export const resolveScopes = (text: string) => {
  // Access denied for orders field
  // This app is not approved to access the Customer object. See https://partners.shopify.com/3441198/apps/97384202241/customer_data for more details.

  const isRequiredAccessCase = text.indexOf("Required access:") != -1

  //
  const scopes = isRequiredAccessCase
    ? extractQuotesScope(text)
        .filter(e => !ignoredScopes.has(e))
        .map(e => (e == "shipping" ? "read_shipping" : e))
    : tokenize(text)
        .flatMap(w => (map as any)[w])
        .filter(e => !!e)
  return _.uniq(scopes)
}

export const documentLink = (entity: string, entityTypes: string[], apiVersion: string) => {
  let entityHelpUrl = ""
  if (entityTypes.includes("union")) {
    entityHelpUrl = `https://shopify.dev/docs/api/admin-graphql/${apiVersion}/unions/${entity}`
  } else if (entityTypes.includes("connection")) {
    entityHelpUrl = `https://shopify.dev/docs/api/admin-graphql/${apiVersion}/connections/${entity}`
  } else if (entityTypes.includes("regular") || entityTypes.includes("money")) {
    entityHelpUrl = `https://shopify.dev/docs/api/admin-graphql/${apiVersion}/objects/${entity}`
  } else {
    console.log(`no doc link found for ${entityTypes.join("/")}`)
  }
  return entityHelpUrl
}

export const difference = (left: string[], right: string[]) => (left || []).filter(element => !right.includes(element))

// provides list of scopes recommended to open for particular root entity
export const proposedScopes = (path: string[], scopes: string[]): string[] => {
  if (path == undefined || scopes == undefined) return []
  const joined = path.join("/")
  switch (joined) {
    case "orders":
      return difference(Scopes.orders, scopes)

    case "companies":
      return difference(["read_companies"], scopes)

    case "collections":
      return difference(["read_products"], scopes)

    case "customers":
      return difference(["read_customers"], scopes)

    case "fulfillmentOrders":
      return difference(Scopes.fulfillments, scopes)

    case "inventoryItems":
      return difference(["read_inventory"], scopes)

    case "locations":
      return difference(["read_locations"], scopes)

    case "productVariants":
      return difference(["read_products"], scopes)

    case "products":
      return difference(["read_products"], scopes)

    case "draftOrders":
      return difference(["read_draft_orders"], scopes)

    case "codeDiscountNodes":
      return difference(["read_discounts"], scopes)

    case "metaobjectDefinitions":
      return difference(Scopes.metaobjects, scopes)
  }

  return []
}

export const scopesBySections = (scopes: string[]): any[] => {
  const allCategorized = Object.entries(Scopes).flatMap(([name, v]) => v)
  const sections = Object.entries(Scopes)
    .filter(([name, v]) => _.intersection(v, scopes).length > 0)
    .map(([name, v]) => ({
      title: name,
      options: v.map(e => ({ value: e, label: e })),
    }))

  const rest = scopes.filter(e => !allCategorized.includes(e)).map(e => ({ value: e, label: e }))

  return [{ options: rest }, ...sections]
}
