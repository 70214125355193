import { PayloadAction, createSlice, current } from "@reduxjs/toolkit"
import { EntityNField2, PageInfo, Task } from "@src/datatypes"
import { collectFields, createTreeData, cutTemporalChildren, extendTree, findNodeByPath, getPageInfo, mapTreeData } from "@src/logic/helpers"
import _ from "lodash"

export interface ExportBadge {
  value: string
  name: string
  format: string
}

export type TreeData = {
  id: string
  field: string
  children?: TreeData[]
  entity?: string
  label?: string
  fields?: string[]
  temporal: boolean
  enf?: EntityNField2
}

export interface BasicState {
  //
  fieldsPath: string[]
  //
  childField: string // for hasNext
  pageInfo?: PageInfo
  //
  running: boolean
  tasks: Task[]
  currTasks: Task[]
  time: number
  taskFieldsPath: string[]

  //
  isQlAdd: boolean // create new ql
  treeData: TreeData // whole created tree
  focusTree: TreeData // currentry visible tree node
  treeQls: { [name: string]: TreeData[] }
  qlKind?: string // what kind ql query is opened
  qlFields: { [name: string]: string[] } // selected fields for opened custom ql
}

export interface SetPageInfo {
  field: string
  data: any
}

const initialState: BasicState = {
  //
  fieldsPath: [],
  //
  childField: undefined,
  pageInfo: undefined,
  //
  running: false,
  tasks: [],
  currTasks: [],
  time: 0,
  taskFieldsPath: [],

  //
  isQlAdd: false,
  treeData: undefined,
  focusTree: undefined,
  treeQls: {},
  qlKind: undefined,
  qlFields: {},
}

const getCurrentTasks = (state: BasicState) => {
  return state.tasks.filter((t: Task) => _.isEqual(t.path, state.fieldsPath))
}

export const basicSlice = createSlice({
  name: "basic",
  initialState,
  reducers: {
    goMenu: (state, action: PayloadAction<any>) => {
      state.fieldsPath = []
      state.pageInfo = undefined
    },

    setTreeQls: (state, action: PayloadAction<any>) => {
      state.treeQls = action.payload
    },

    setFieldsPath: (state, action: PayloadAction<{ path: string[]; enfs: EntityNField2[]; up: boolean }>) => {
      state.fieldsPath = action.payload.path

      const st = current(state)
      if (st.isQlAdd) {
        const clon = _.cloneDeep(st.treeData) // take current tree
        const tmpTree = extendTree(clon, action.payload.enfs) // extends it with new node
        state.treeData = action.payload.up ? cutTemporalChildren(tmpTree) : tmpTree
        const targetNode = findNodeByPath(clon, action.payload.path)
        state.focusTree = { ...targetNode }
      }

      state.currTasks = getCurrentTasks(current(state))
    },

    //
    setPageInfo: (state, action: PayloadAction<SetPageInfo>) => {
      state.childField = action.payload.field
      state.pageInfo = getPageInfo(action.payload.data, 0)
    },
    //

    startNewTask: (state, action: PayloadAction<any>) => {
      state.running = action.payload.running
      state.taskFieldsPath = action.payload.fieldsPath
    },

    setTasksState: (state, action: PayloadAction<any>) => {
      state.tasks = action.payload.tasks
      state.time = action.payload.time

      state.currTasks = getCurrentTasks(current(state))
    },

    //
    startNewQl: (state, action: PayloadAction<TreeData>) => {
      state.isQlAdd = true
      state.treeData = action.payload

      state.qlKind = undefined
    },

    stopNewQl: (state, action: PayloadAction<any>) => {
      state.isQlAdd = false
      state.treeData = undefined
      state.focusTree = undefined

      state.qlKind = undefined
    },

    addChildQl: (state, action: PayloadAction<{ path: string[]; item: EntityNField2 }>) => {
      const st = current(state)
      //const childs = action.payload.childEnfs.map(e => ({ id: e.field, field: e.field, entity: e.entity, temporal: false } as TreeData))
      const child = createTreeData(action.payload.item, false)

      //console.log("in slice", childs)
      //const clon = _.cloneDeepWith(st.treeData, v => ({ ...v, temporal: false }))
      //console.log("st.treeData", st.treeData)
      const clon = _.cloneDeep(st.treeData)
      //console.log("path", action.payload.path)
      const targetNode = findNodeByPath(clon, action.payload.path)
      //console.log("targetNode", targetNode)

      const safeChildren = targetNode.children || []
      const presentFields = safeChildren.map(e => e.field)
      if (!presentFields.includes(child.field)) {
        targetNode.children = [...safeChildren, child]

        state.treeData = { ...clon } as TreeData
        state.focusTree = { ...targetNode }
      }
    },

    deleteChildQl: (state, action: PayloadAction<{ path: string[]; item: EntityNField2 }>) => {
      const st = current(state)

      const clon = _.cloneDeep(st.treeData)
      const targetNode = findNodeByPath(clon, action.payload.path)

      const safeChildren = targetNode.children || []
      const presentFields = safeChildren.map(e => e.field)

      const field = action.payload.item.field
      if (presentFields.includes(field)) {
        targetNode.children = safeChildren.filter(e => e.field != field)

        state.treeData = { ...clon } as TreeData
        state.focusTree = { ...targetNode }
      }
    },

    setFocusQlFields: (state, action: PayloadAction<string[]>) => {
      ///state.focusTree.fields = [...action.payload]
    },

    //
    setCustomQlFields: (state, action: PayloadAction<{ kindName: string; fields: string[] }>) => {
      ///state.focusTree.fields = [...action.payload]
      console.log("update qlf", action.payload.fields)
      state.qlFields[action.payload.kindName] = action.payload.fields

      //enrich
      const st = current(state)
      if (st.isQlAdd) {
        const enrichFields = (td: TreeData) => {
          const fields = st.qlFields[td.field] // todo nameKind
          return fields ? { ...td, fields } : td
        }
        state.treeData = mapTreeData(st.treeData, enrichFields)
      }
    },

    // custom ql query opened
    openCustomQl: (state, action: PayloadAction<{ kind: string; tree: TreeData }>) => {
      state.qlKind = action.payload.kind
      state.treeData = action.payload.tree
      state.qlFields = collectFields(action.payload.tree)
      //
      state.isQlAdd = false
      state.focusTree = undefined
    },
  },
})

export const {
  setFieldsPath,
  setPageInfo,
  setTasksState,
  goMenu,
  startNewTask,
  startNewQl,
  stopNewQl,
  addChildQl,
  deleteChildQl,
  setFocusQlFields,
  setTreeQls,
  openCustomQl,
  setCustomQlFields,
} = basicSlice.actions

export default basicSlice.reducer
