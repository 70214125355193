import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { setMetaState } from "@src/reducers/metaSlice"
import MetaCtx, { loadApiVersion } from "@src/services/MetaCtx"
import { fetchZip } from "@src/services/fetchZip"
import _ from "lodash"
import { useEffect, useState } from "react"

const useMeta = () => {
  const [metaCtx, setMetaCtx] = useState(null)

  const dispatch = useAppDispatch()
  const data = useAppSelector(state => state.meta.data)

  useEffect(() => {
    const apiVersion = loadApiVersion()
    if (_.isEmpty(data)) {
      shopify.loading(true)
      fetchZip(`/scheme-${apiVersion}.gz`)
        .then(r => {
          const ctx = new MetaCtx(r, apiVersion)
          setMetaCtx(ctx)
          dispatch(setMetaState({ data, apiVersion }))
        })
        .finally(() => shopify.loading(false))
    } else {
      const ctx = new MetaCtx(data, apiVersion)
      setMetaCtx(ctx)
    }
  }, [data])

  return { metaCtx } as const
}

export default useMeta
