import { removePageInfo } from "@src/logic/helpers"
import { setPageInfo } from "@src/reducers/basicSlice"
import { useAppDispatch } from "@src/reducers/hooks"
import { serverClient } from "@src/services/ServerClient"
import _ from "lodash"
import { useState } from "react"

const useQueryTabs = (): any => {
  const dispatch = useAppDispatch()

  const [selectedTab, setSelectedTab] = useState(0)

  const [resultQuery, setResultQuery] = useState<string>(null)
  const [{ result, resultTag, resultRaw, resultError }, setResults] = useState({ result: "", resultTag: null, resultRaw: null, resultError: null })
  const [loadingResult, setLoadingResult] = useState<boolean>(false)
  const [text, setText] = useState("")

  const resultToText = (obj: any) => JSON.stringify(obj, null, 2)

  const queryCall = (call: any) => {
    setLoadingResult(true)

    serverClient.runProxyQuery("", call, "justQuery").then(resQuery => {
      setResultQuery(resQuery)
    })

    serverClient
      .runProxyQuery("", call, "proxy")
      .then(r => handleQueryResult("", call.path, r))
      .finally(() => setLoadingResult(false))
  }

  const qlQueryCall = (call: any) => {
    setLoadingResult(true)

    /*serverClient.runProxyQuery("", call, "justQuery").then(resQuery => {
      setResultQuery(resQuery)
    })*/

    serverClient
      .runProxyQuery("", call, "qlProxy")
      .then(r => {
        setResultQuery(r[1])
        handleQueryResult("", call.path, JSON.parse(r[0]))
      })
      .finally(() => setLoadingResult(false))
  }

  const handleQueryResult = (field: string, path: string[], r: any) => {
    if (r.errors?.length > 0 /* && ("" + r.errors[0].message).indexOf("Access denied") == -1*/) {
      const errorStr = resultToText(r.errors)

      setSelectedTab(0)
      setResults({ result: null, resultTag: null, resultRaw: r, resultError: errorStr })

      setText(errorStr)
    } else {
      const res = removePageInfo(r.data)
      dispatch(setPageInfo({ field, data: res }))

      setSelectedTab(0)
      setResults({ result: res, resultTag: _.last(path), resultRaw: r, resultError: null })

      setText(resultToText(res))
    }
  }

  const tabs = [
    resultTag && {
      id: "result",
      content: resultTag,
      panelID: "result",
    },
    resultError && {
      id: "error",
      content: "error",
      panelID: "error",
    },
    resultQuery && {
      id: "query",
      content: "query",
      panelID: "query",
    },
    resultRaw && {
      id: "raw",
      content: "raw",
      panelID: "raw",
    },
  ].filter(Boolean)

  const handleTabChange = (tabIndex: number) => {
    setSelectedTab(tabIndex)
    const tab = tabs[tabIndex]

    if (tab.id == "result") {
      setText(resultToText(result))
    } else if (tab.id == "error") {
      setText(resultError)
    } else if (tab.id == "query") {
      setText(resultQuery)
    } else if (tab.id == "raw") {
      setText(resultToText(resultRaw))
    }
  }

  const getQueryHandler = () => {
    const tab = tabs[selectedTab]
    if (tab.id == "result") {
      navigator.clipboard.writeText(resultToText(result))
    } else if (tab.id == "error") {
      navigator.clipboard.writeText(resultError)
    } else if (tab.id == "query") {
      navigator.clipboard.writeText(resultQuery)
    } else if (tab.id == "raw") {
      navigator.clipboard.writeText(resultRaw)
    }
  }

  return { queryCall, qlQueryCall, text, tabs, selectedTab, loadingResult, getQueryHandler, handleTabChange }
}

export default useQueryTabs
