import { configureStore } from "@reduxjs/toolkit"
import basicReducer from "@src/reducers/basicSlice"
import starReducer from "@src/reducers/starSlice"
import metaReducer from "@src/reducers/metaSlice"
import treeReducer from "@src/reducers/treeSlice"

export const store = configureStore({
  reducer: {
    basic: basicReducer,
    stars: starReducer,
    meta: metaReducer,
    tree: treeReducer
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
