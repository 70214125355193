import { Button, ButtonGroup, Card, Collapsible, InlineStack, Text } from "@shopify/polaris"
import { PlayIcon } from "@shopify/polaris-icons"
import Const from "@src/logic/const"
import { findEntityByName } from "@src/pages/SchemeWalk"
import { setCustomQlFields, TreeData } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import MetaCtx from "@src/services/MetaCtx"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import FieldsBlock from "./FieldsBlock"
import { Placeholder } from "./Placeholder"

interface Props {
  tree: TreeData
  metaCtx: MetaCtx
  isRoot: boolean
  qlFetchHandler?: (kind: string, label?: string) => void
}

const ChildFieldsComponent: React.FC<Props> = ({ tree, metaCtx, isRoot, qlFetchHandler }) => {
  const [selectedFields, setSelectedFields] = useState([])

  const dispatch = useAppDispatch()

  const qlFields = useAppSelector(state => state.basic.qlFields)

  const entity = findEntityByName(metaCtx.data, tree.entity)

  const fieldsAmount = entity?.fields.length || 0
  const hasFields = fieldsAmount > 0
  const alwaysShow = hasFields && fieldsAmount <= Const.FieldsNoCollapseAmount

  const updateQlEntityFields = (kindName: string, fields: string[]) => {
    dispatch(setCustomQlFields({ kindName, fields }))
  }

  const updateQlEntityFieldsDebounced = _.debounce(updateQlEntityFields, Const.SaveDebounceTime)

  const handleSelectionChange = (selection: string[], isQlAdd: boolean) => {
    setSelectedFields(selection)

    updateQlEntityFieldsDebounced(tree.field, selection) //todo nameKind
  }

  useEffect(() => {
    const fields = qlFields[tree.field] // todo nameKind  tree.field + tree.label
    fields && setSelectedFields(fields)
  }, [])

  const fieldsBlock = <FieldsBlock {...{ entity, isQlAdd: false, selectedFields, handleSelectionChange, loading: false }} />

  return (
    <>
      <Placeholder height="10px" />

      <Card>
        {isRoot ? (
          <InlineStack align="end">
            {/* <ExportPopover fields={selectedFields} metaCtx={metaCtx} /> */}
            <Placeholder width="20px" />

            <ButtonGroup variant="segmented">
              <Button icon={PlayIcon} disabled={false} onClick={() => qlFetchHandler(tree.field, tree.label)} />
            </ButtonGroup>
          </InlineStack>
        ) : (
          <>
            <Text as="p" variant="headingMd">
              {entity.name}
            </Text>
            <Text as="p" variant="headingSm">
              {`/${tree.field}`}
            </Text>
          </>
        )}

        {alwaysShow ? fieldsBlock : null}
        {hasFields && !alwaysShow ? (
          <Collapsible id="fields-collaps" open={true} transition={{ duration: "200ms", timingFunction: "ease-in-out" }}>
            {fieldsBlock}
          </Collapsible>
        ) : null}
      </Card>
    </>
  )
}

export default ChildFieldsComponent
