import { UserEntity } from "@src/datatypes"
import Const from "@src/logic/const"
import { useQuery } from "@tanstack/react-query"

const useUserEntityQuery = (enabled: boolean, fieldsPath: string[], isQlAdd: boolean, handler: (userEntity: UserEntity) => any) =>
  useQuery({
    enabled,
    queryKey: ["userEntity", fieldsPath],
    queryFn: () =>
      fetch(`/api/userEntity?store=${Const.Store}&kind=${fieldsPath[0]}&path=${fieldsPath.join(",")}&qladd=${isQlAdd}`)
        .then(response => response.json())
        .then(userEntity => {
          if (userEntity) {
            handler(userEntity)
          }
          return userEntity
        }),
  })

export default useUserEntityQuery
