import { Task } from "@src/datatypes"
import Const from "@src/logic/const"
import { useQuery } from "@tanstack/react-query"

const useTasksQuery = (store: string, handler: (tasks: Task[], time: number) => any) =>
  useQuery({
    enabled: !!store,
    queryKey: ["store", store],
    queryFn: () =>
      fetch(`/api/tasks?store=${Const.Store}`)
        .then(response => response.json())
        .then(tasks => {
          //console.log("tasks", tasks)
          if (!!tasks) {
            handler(tasks[0], tasks[1])
          }
          return tasks
        }),
  })

export default useTasksQuery
