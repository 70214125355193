import { Entity } from "@src/datatypes"
import Const from "../logic/const"
import Control from "@src/logic/Control"

const ignoreEntities = new Set(["ProductPublicationConnection"])

class MetaCtx {
  data: Entity[]
  apiVersion: string
  private dataMapById: Record<number, Entity>
  private dataMapByName: Record<string, Entity>

  control: Control

  constructor(tmp: Entity[], apiVersion: string) {
    this.apiVersion = apiVersion
    this.data = tmp
      .filter(e => !ignoreEntities.has(e.name))
      .map(e => {
        const filteredEntities = e.entities.filter(f => !ignoreEntities.has(f.entity))
        return filteredEntities.length != e.entities.length ? { ...e, entities: filteredEntities } : e
      })
    this.dataMapById = this.data.reduce((acc, entity) => ({ ...acc, [entity.id]: entity }), {})
    this.dataMapByName = this.data.reduce((acc, entity) => ({ ...acc, [entity.name]: entity }), {})
    this.control = new Control(this)
  }
  getEntityByName = (entity: string) => this.dataMapByName[entity]

  getEntityById = (entity: number) => this.dataMapById[entity]
}

export const loadApiVersion = () => localStorage.getItem("apiVersion") || Const.ApiVersion

export default MetaCtx
