import useMeta from "@src/hooks/useMeta"
import MetaCtx from "@src/services/MetaCtx"
import React, { createContext } from "react"

export type WrapProps = {
  children: React.ReactNode
}

export const MetaContext = createContext<MetaCtx>(null)

const MetaWrap: React.FC<WrapProps> = ({ children }) => {
  const { metaCtx } = useMeta()

  return <MetaContext.Provider value={metaCtx}> {metaCtx && children}</MetaContext.Provider>
}

export default MetaWrap
