import { MaximizeIcon, MinimizeIcon } from "@shopify/polaris-icons"
import { useCallback, useState } from "react"
import { useSearchParams } from "react-router-dom"

const useFullscreen = () => {
  const [isFullscreen, setFullscreen] = useState(false)
  const [, setSearchParams] = useSearchParams()

  const switchSerarchParams = (fullscreen: boolean) =>
    setSearchParams(prevParams => {
      return {
        ...prevParams,
        fullscreen,
      }
    })

  const handleEnterFullscreen = useCallback(() => {
    switchSerarchParams(true)
    setFullscreen(true)
    
    //fullscreen.dispatch(Fullscreen.Action.ENTER)
  }, [])

  const handleExitFullscreen = useCallback(() => {
    switchSerarchParams(false)
    setFullscreen(false)
    //fullscreen.dispatch(Fullscreen.Action.EXIT)
  }, [])

  const maximizeAction = {
    key: "max",
    onAction: handleEnterFullscreen,
    icon: MaximizeIcon,
  }

  const minimizeAction = {
    key: "min",
    onAction: handleExitFullscreen,
    icon: MinimizeIcon,
  }

  const fullscreenAction = isFullscreen ? minimizeAction : maximizeAction

  return [isFullscreen, fullscreenAction] as const
}

export default useFullscreen
