import MetaCtx from "./MetaCtx"

const fetchJson = (metaCtx: MetaCtx, url: string, paths: string[], fields: string[]) =>
  fetch(url)      
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = paths.join("_") + ".jsonl"
      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    })
    .catch(error => {
      console.error("Error streaming JSONL data:", error)
    })


export default fetchJson
