// read_customers,read_companies,read_products,read_returns,read_inventory,read_locations,read_fulfillments
const Scopes: Record<string, string[]> = {
  "Orders and Fulfillment": [
    "read_all_orders",
    "read_orders",
    "read_draft_orders",
    "read_fulfillments",
    "read_assigned_fulfillment_orders",
    "read_merchant_managed_fulfillment_orders",
    "read_third_party_fulfillment_orders",
    /*, "read_marketplace_fulfillment_orders"*/
    "read_returns",
    //"write_draft_orders",
    //"write_orders",
  ],
  "Products and Inventory": ["read_products", "read_product_listings", "read_inventory"/*, "write_products"*/],
  "Customers and Companies": ["read_customers", "read_customer_merge", "read_companies"/*, "write_customers"*/],
  "Discounts and Markets": ["read_discounts", "read_markets"],
  "Metaobjects and Locations": ["read_metaobjects", "read_metaobject_definitions", "read_locations", "read_publications"],
}

export default Scopes
