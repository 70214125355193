import { EntityNField } from "@src/datatypes"
import _ from "lodash"
import { resolveChildren } from "../logic/treeResolve"
import { collectCsvIdLinks, objectToCsv } from "../pages/SchemeWalk"
import MetaCtx from "./MetaCtx"

const fetchCsvStream = (metaCtx: MetaCtx, url: string, paths: string[], fields: string[]) =>
  fetch(url)
    .then(response => {
      const headers = collectCsvIdLinks(paths).concat(fields)
      const headersCsv = headers.join(",")
      const enfs = resolveChildren(metaCtx, paths)

      const isRoot = paths.length == 1

      // https://developer.mozilla.org/en-US/docs/Web/API/ReadableStreamDefaultReader/read
      const utf8Decoder = new TextDecoder("utf-8")
      const utf8Encoder = new TextEncoder()

      const reader = response.body.getReader()
      const stream = new ReadableStream({
        start(controller) {
          controller.enqueue(utf8Encoder.encode(headersCsv + "\n"))

          let currentLine = ""

          function push() {
            reader
              .read()
              .then(({ done, value }) => {
                if (done) {
                  controller.close()
                  return
                }

                const chunk = value ? utf8Decoder.decode(value, { stream: true }) : ""
                const result = (currentLine + chunk).split(/[\r\n]/)

                result.forEach((line, index) => {
                  //console.log("line", index, line)

                  if (line != "") {
                    try {
                      const obj = JSON.parse(line)
                      currentLine = ""

                      const allowProcess = isRoot || !isRoot /*&& obj && obj.hasOwnProperty("__parentId")*/
                      //console.log("obj", allowProcess, isRoot, obj)
                      if (obj && allowProcess) {
                        const csvObj = jsonToCsv(obj, paths, enfs)
                        if (csvObj) {
                          try {
                            const csv = objectToCsv(headers, csvObj).join(",")
                            //console.log('csv', csv)
                            controller.enqueue(utf8Encoder.encode(csv + "\n"))
                          } catch (e) {
                            console.error(e)
                          }
                        }
                      }
                    } catch (err) {
                      //console.error(err)
                      currentLine = line
                    }
                  }
                })

                push()
              })
              .catch(error => {
                console.error(error)
                controller.error(error)
              })
          }
          push()
        },
      })
      return new Response(stream, { headers: { "Content-Type": "text/csv" } })
    })
    .then(response => response.blob())
    .then(blob => {
      const link = document.createElement("a")
      link.href = URL.createObjectURL(blob)
      link.download = paths.join("_") + ".csv"
      document.body.appendChild(link)

      link.click()

      document.body.removeChild(link)
    })
    .catch(error => {
      console.error("Error streaming CSV data:", error)
    })

const goodObj = (str: string) => str.charAt(0) == "{" && str.charAt(str.length - 1) == "}"

export const textToLines = (text: string): string[] => {
  const lines = text.split("\n")
  let result: string[] = []
  let candidate = lines[0]
  for (let i = 1; i < lines.length; i++) {
    const next = lines[i]

    //console.log("candidate", candidate)
    //console.log("next", next)

    const goodSplit = goodObj(candidate)
    //(candidate.charAt(candidate.length - 1) == "}" || candidate.charAt(candidate.length - 2) == "}") && (next.charAt(0) == "{" || next.charAt(1) == "{")
    if (goodSplit) {
      result.push(candidate)
      candidate = next
      //console.log("yield")
    } else {
      //console.log("join")
      candidate = candidate + "\n" + next
    }
  }

  result = result.concat(candidate)

  return result
}

const isConnection = (e: string) => e.endsWith("Connection")

export const jsonToCsv = (item: any, paths: string[], enfs: EntityNField[]) => {
  const xtract = (obj: any, objName: string, paths: string[], pfx: string[]): any =>
    (obj &&
      Object.keys(obj).flatMap((key: string) => {
        const value = obj[key]
        if (value && paths.length == 1 && key == paths[0]) {
          return Object.entries(value)
        } else if (typeof value === "object") {
          return xtract(value, key, paths.slice(1), pfx.concat(key))
        } else if (key == "id") {
          const path: string = pfx.concat("id").join("_")
          return [[path, value]]
        } else return []
      })) ||
    []

  //const enf0 = enfs[0]
  //console.log("enf0", enf0)
  const lastEnf = _.last(enfs)

  if (paths.length == 1) {
    // single root connection
    const result = item
    //console.log("entries", result)
    return result
  } else if (paths.length > 1 /*&& isConnection(enf0.entity)*/ && !isConnection(lastEnf.entity)) {
    // connection/regular
    const preLast = paths.slice(-2)[0]

    const entries = xtract(item, preLast, paths.slice(-1), [])
    const result = Object.fromEntries(entries.filter((e: any) => e))
    return result
  } else if (paths.length > 1 /*&& isConnection(enf0.entity)*/ && isConnection(lastEnf.entity)) {
    // connection/regular
    if (!item.hasOwnProperty("__parentId")) return undefined
    //const entries = xtract(item, paths[0], paths.slice(1), [])
    const result = item //Object.fromEntries(entries.filter((e: any) => e))
    return result
  } else {
    const entries = xtract(item, paths[0], paths.slice(1), [])
    const result = Object.fromEntries(entries.filter((e: any) => e))
    //console.log("entries", result)
    return result
  }
}

export default fetchCsvStream
