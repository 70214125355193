import { Split } from "@geoffcox/react-splitter"
import { Badge, BlockStack, Box, Button, ButtonGroup, InlineStack, MenuGroupDescriptor, Page, Tabs } from "@shopify/polaris"
import { CheckIcon, ClipboardIcon, ClockIcon, DatabaseAddIcon, RefreshIcon, SettingsIcon } from "@shopify/polaris-icons"
import Breadcrumb from "@src/components/Breadcrumb"
import CodeComponent from "@src/components/CodeComponent"
import EntitiesList from "@src/components/EntitiesList"
import EntityFilterPopover from "@src/components/EntityFilterPopover"
import TreeComponent from "@src/components/TreeComponent"
import { CurrentStateContext } from "@src/context/CurrentStateWrap"
import { MetaContext } from "@src/context/MetaWrap"
import { ResolvedUrlContext } from "@src/context/ResolveUrlWrap"
import { UserEntityContext } from "@src/context/UserEntityWrap"
import { Task } from "@src/datatypes"
import useExportTasks, { hasCsvTaskCompleted, hasCsvTaskCreated, hasJsonTaskCompleted, hasJsonTaskCreated } from "@src/hooks/useExportTasks"
import useFullscreen from "@src/hooks/useFullscreen"
import useQueryTabs from "@src/hooks/useQueryTabs"
import useTasksQuery from "@src/hooks/useTasksQuery"
import Const from "@src/logic/const"
import devSettings from "@src/logic/devSettings"
import { mapTreeData } from "@src/logic/helpers"
import { setTasksState, TreeData } from "@src/reducers/basicSlice"
import { useAppDispatch, useAppSelector } from "@src/reducers/hooks"
import { setMetaState } from "@src/reducers/metaSlice"
import { serverClient } from "@src/services/ServerClient"
import { fetchZip } from "@src/services/fetchZip"
import React, { useCallback, useContext } from "react"
import { useNavigate } from "react-router-dom"

const calcBadge = (tasks: Task[]) => {
  const hasNewCsv = hasCsvTaskCreated(tasks)
  const hasNewJson = hasJsonTaskCreated(tasks)
  const hasCsv = hasCsvTaskCompleted(tasks)
  const hasJson = hasJsonTaskCompleted(tasks)
  if (hasNewCsv && hasNewJson) {
    return (
      <Badge tone="info" icon={ClockIcon}>
        csv, json
      </Badge>
    )
  } else if (hasNewCsv || hasNewJson) {
    return (
      <Badge tone="info" icon={ClockIcon}>
        {hasNewCsv ? "csv" : "json"}
      </Badge>
    )
  } else if (hasCsv && hasJson) {
    return (
      <Badge tone="success" icon={CheckIcon}>
        csv, json
      </Badge>
    )
  } else if (hasCsv || hasJson) {
    return (
      <Badge tone="info" icon={CheckIcon}>
        {hasCsv ? "csv" : "json"}
      </Badge>
    )
  } else return undefined
}

const calcQlBadge = () => {
  return (
    <Badge tone="info" icon={DatabaseAddIcon}>
      add query
    </Badge>
  )
}

const EntityPage: React.FC = () => {
  const [isFullscreen, fullscreenAction] = useFullscreen()

  const dispatch = useAppDispatch()
  const nav = useNavigate()

  const metaCtx = useContext(MetaContext)

  const { queryCall, qlQueryCall, text, tabs, selectedTab, loadingResult, getQueryHandler, handleTabChange } = useQueryTabs()

  const { fieldsPath, fieldsEnfs, entity, updateResolvedUrlState } = useContext(ResolvedUrlContext)
  const { userEntity, loadPreview, loadingPreview } = useContext(UserEntityContext)
  const { flushState } = useContext(CurrentStateContext)

  useExportTasks()

  const currTasks = useAppSelector(state => state.basic.currTasks)
  const isQlAdd = useAppSelector(state => state.basic.isQlAdd)
  //
  const treeData = useAppSelector(state => state.basic.treeData)
  const qlKind = useAppSelector(state => state.basic.qlKind)
  const qlFields = useAppSelector(state => state.basic.qlFields)

  const apiVersion = metaCtx.apiVersion

  const { isLoading: taskLoading } = useTasksQuery(userEntity.store, (tasks, time) => dispatch(setTasksState({ tasks, time })))

  const refreshAction = (store: string) => {
    loadPreview(store, true)
  }

  const backHandler = useCallback(() => {
    const result = updateResolvedUrlState(fieldsEnfs.slice(0, -1), true)

    if (result.path) {
      flushState()
      nav(`/${result.path}`)
    } else {
      nav("/")
    }
  }, [fieldsPath, fieldsEnfs])

  const fetchHandler = (selected: string[]) => {
    //console.log("selected", selected)
    const call = {
      store: Const.Store,
      version: apiVersion,
      query: "",
      path: fieldsPath,
      fields: selected,
    }

    queryCall(call)
  }

  const qlFetchHandler = useCallback(
    (kind: string, label?: string) => {
      //console.log("customQl", qlFields, qlKind, treeData)

      const enrichFields = (td: TreeData) => {
        const fields = qlFields[td.field] // todo nameKind
        return fields ? { ...td, fields } : td
      }
      const result = mapTreeData(treeData, enrichFields)

      const call = {
        store: Const.Store,
        version: apiVersion,
        query: JSON.stringify(result),
        path: fieldsPath,
        fields: [kind, label],
      }

      //console.log("call", call)

      qlQueryCall(call)
    },
    [treeData, qlFields, qlKind]
  )

  const fetchChildHandler = useCallback(
    (field: string, query: string) => {
      const call = {
        store: Const.Store,
        version: apiVersion,
        query,
        path: fieldsPath.concat([field]),
      }

      queryCall(call)
    },
    [fieldsPath]
  )

  const refreshActionDescriptor = {
    key: "refresh",
    onAction: useCallback(() => refreshAction(userEntity?.store), [userEntity]),
    icon: RefreshIcon,
  }

  const flushCacheHandler = (scope: string) => () => serverClient.flushCacheQuery(userEntity?.store, scope)

  const apiVersionChangeHandler = (apiVersion: string) => {
    localStorage.setItem("apiVersion", apiVersion)
    fetchZip(`/scheme-${apiVersion}.gz`).then(data => {
      dispatch(setMetaState({ data, apiVersion }))
    })
  }

  const actionGroups: MenuGroupDescriptor[] = [
    devSettings.testMode && {
      title: "system",
      actions: [
        { content: "flush user", onAction: flushCacheHandler("user") },
        { content: "flush star", onAction: flushCacheHandler("star") },
        { content: "flush store", onAction: flushCacheHandler("store") },
        { content: "flush task", onAction: flushCacheHandler("task") },
        { content: "flush preview", onAction: flushCacheHandler("preview") },
        { content: "flush all", onAction: flushCacheHandler("all") },
      ],
    },
    {
      icon: SettingsIcon,
      title: "",
      actions: [
        { content: "Permissions", onAction: () => nav("/scopes") },
        { content: "Subscriptions", onAction: () => nav("/subscribe") },
        { content: "Support", onAction: () => nav("/support") },
      ],
    },
    {
      title: apiVersion,
      actions: Const.ApiVersions.filter(e => e != apiVersion).map(v => ({
        content: v,
        onAction: () => apiVersionChangeHandler(v),
      })),
    },
  ].filter(Boolean)

  const secondaryActions = isFullscreen ? [refreshActionDescriptor] : [/*fullscreenAction, */ refreshActionDescriptor]
  const showEntityFilter = qlKind == undefined

  shopify.loading(taskLoading || loadingResult)

  const title = /*isQlAdd ? entity?.name + " /create new query" :*/ entity?.name

  return (
    <Page
      fullWidth
      title={title}
      backAction={{ content: "menu", onAction: () => backHandler() }}
      /*secondaryActions={secondaryActions}*/
      primaryAction={showEntityFilter ? <EntityFilterPopover /> : null}
      titleMetadata={isQlAdd ? calcQlBadge() : calcBadge(currTasks)}
      additionalMetadata={<Breadcrumb />}
      actionGroups={actionGroups}
    >
      <Split initialPrimarySize="30%" minPrimarySize="400px">
        <EntitiesList {...{ fetchHandler, fetchChildHandler, qlFetchHandler, loading: loadingPreview }} />

        {isQlAdd ? (
          <TreeComponent />
        ) : (
          <BlockStack>
            <InlineStack align="space-between">
              {/* <div style={{ border: "1px solid red" }}> */}
              <Tabs tabs={tabs} selected={selectedTab} onSelect={handleTabChange} fitted={false}>
                <Box padding="0" width="500px" minHeight="1px" />
              </Tabs>
              {/* </div> */}

              <ButtonGroup variant="segmented">
                <Button icon={ClipboardIcon} onClick={getQueryHandler} variant="secondary"></Button>
              </ButtonGroup>
            </InlineStack>

            <CodeComponent text={text} />
          </BlockStack>
        )}
      </Split>
    </Page>
  )
}

export default EntityPage
