import { PayloadAction, createSlice } from "@reduxjs/toolkit"
import { KindStars } from "@src/datatypes"

export interface StoreStarsState {
  store: string
  stars: KindStars[]
}

const initialState: StoreStarsState = {
  store: null,
  stars: [],
}

export const starSlice = createSlice({
  name: "stars",
  initialState,
  reducers: {
    // to serve menu page
    setStarState: (state, action: PayloadAction<any>) => {
      state.stars = action.payload.kinds
      //console.log('setStarState',action.payload.kinds)
    },
  },
})

export const { setStarState } = starSlice.actions

export default starSlice.reducer
