import _ from "lodash"
import React, { createContext, useContext, useEffect, useState } from "react"
import { WrapProps } from "./MetaWrap"
import { ResolvedUrlContext } from "./ResolveUrlWrap"
import { UserEntityContext } from "./UserEntityWrap"

type CurrentState = {
  selectedFields: string[]
  fieldsOpen: boolean
  resolved: boolean
}
type FieldsOpenMut = (open: boolean) => void
type SelectedFieldsMut = (fields: string[]) => void
type CurrentStateMut = CurrentState & {
  setFieldsOpen: FieldsOpenMut
  setSelectedFields: SelectedFieldsMut
  entityFilters: string[]
  setEntityFilters: React.Dispatch<React.SetStateAction<string[]>>
  flushState: () => void
}

export const CurrentStateContext = createContext<CurrentStateMut>(null)

const EmptyState: CurrentState = { fieldsOpen: true, selectedFields: [], resolved: false }

const defaultFilters = ["regular", "connection"]

const CurrentStateWrap: React.FC<WrapProps> = ({ children }) => {
  const { entity } = useContext(ResolvedUrlContext)
  const { userEntity } = useContext(UserEntityContext)

  const [state, setState] = useState<CurrentState>(EmptyState)

  const setFieldsOpen: FieldsOpenMut = (fieldsOpen: boolean) => setState({ ...state, fieldsOpen })
  const setSelectedFields: SelectedFieldsMut = (selectedFields: string[]) => setState({ ...state, selectedFields })
  const [entityFilters, setEntityFilters] = useState(defaultFilters)
  const flushState = () => setState(EmptyState)

  useEffect(() => {
    const allEntityFields = entity.fields.map(f => f.name)
    const initSelectedFields = _.intersection(userEntity.fields.fields, allEntityFields)
    const isFieldsOpen = userEntity.fields.opened

    setState({ ...state, fieldsOpen: isFieldsOpen, selectedFields: initSelectedFields, resolved: true })
  }, [entity, userEntity])

  return (
    <CurrentStateContext.Provider value={{ ...state, setFieldsOpen, setSelectedFields, entityFilters, setEntityFilters, flushState }}>
      {" "}
      {state.resolved && children}
    </CurrentStateContext.Provider>
  )
}

export default CurrentStateWrap
